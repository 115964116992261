// hooks/useRoles.ts

import { useState, useEffect } from 'react';
import roleService from '../services/roleService';
import { Role } from '../models/Role';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export const useRoles = () => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchRoles = async () => {

        setLoading(true);
        setError(null);

        try {
            const rolesData = await roleService.getAllRoles();
            setRoles(rolesData);
        } catch (err) {
            setError('Failed to fetch roles');
            showErrorToast(`Failed fetching all roles ${err}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    return { roles, loading, error };
};
