// services/roleService.ts

import axios from 'axios';
import { Role } from '../models/Role';

const API_BASE_URL = process.env.REACT_APP_SERVER_URL; // Replace with your backend API base URL

const roleService = {

    getAllRoles: async (): Promise<Role[]> => {
        try {
            const response = await axios.get<Role[]>(`${API_BASE_URL}/roles/getAllRoles`);
            return response.data;
        } catch (error) {
            console.error('Error fetching roles:', error);
            throw error;
        }
    },

    getRoleById: async (id: bigint): Promise<Role> => {
        try {
            const response = await axios.get<Role>(`${API_BASE_URL}/roles/getRoleById/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching role with id ${id}:`, error);
            throw error;
        }
    },

    createRole: async (roleData: Role): Promise<Role> => {
        try {
            const response = await axios.post<Role>(`${API_BASE_URL}/roles/add`, roleData);
            return response.data;
        } catch (error) {
            console.error('Error creating role:', error);
            throw error;
        }
    },

    updateRole: async (id: bigint, roleData: Role): Promise<Role> => {
        try {
            const response = await axios.put<Role>(`${API_BASE_URL}/roles/update/${id}`, roleData);
            return response.data;
        } catch (error) {
            console.error(`Error updating role with id ${id}:`, error);
            throw error;
        }
    },

    deleteRole: async (id: bigint): Promise<void> => {
        try {
            await axios.delete(`${API_BASE_URL}/roles/delete/${id}`);
        } catch (error) {
            console.error(`Error deleting role with id ${id}:`, error);
            throw error;
        }
    }
};

export default roleService;
