import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../components/Dropdown';
import { Badge } from '../../components/Badge';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input, InputGroup } from '../../components/Input';
import { Divider } from '../../components/Divider';
import { UserService } from '../../services/userService'; // Import the UserService
import { Avatar } from '../../components/Avatar';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination'; // Import the Pagination component
import useUserService from '../../hooks/useUserService';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/Dialog';
import { UserContext } from '../../context/userContext';
import { useTranslation } from 'react-i18next';
import { useRoles } from '../../hooks/useRoles';

const GHUsersPage: React.FC = () => {
    const { users, loading, error, totalUsers, fetchUsers } = useUserService(); // Custom hook using UserService
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [currentPage, setCurrentPage] = useState<number>(1); // State to track the current page
    const [usersPerPage] = useState<number>(10); // Number of users per page
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null); // ID of the user to be deleted
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();
    const { roles } = useRoles();
    const [selectedRole, setSelectedRole] = useState<string | null>('All'); // Default to 'All'

    // Filter users based on the search query and selected role, excluding current user
    const filteredUsers = Array.isArray(users)
        ? users.filter((user) => {
            const matchesSearch = user.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.email?.toLowerCase().includes(searchQuery.toLowerCase());
            const isNotCurrentUser = user.id !== currentUser!.id;
            const matchesRole = selectedRole === 'All' || user.rolename === selectedRole;
            return matchesSearch && isNotCurrentUser && matchesRole;
        }) : [];

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    useEffect(() => {
        document.title = `${t('ghsidebar_users')}`
    }, []);

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (userId: string) => {
        setSelectedUserId(userId);
        setIsOpen(true);
    };

    // Handle delete user
    const handleDelete = async () => {
        if (!selectedUserId) return;

        setIsDeleting(true);
        try {
            await UserService.deleteUser(selectedUserId); // Call the delete API
            fetchUsers(); // Refresh the user list
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete user:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>{t('user_account')}</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder={`${t('search')} ${t('users')}${String.fromCharCode(8230)}`}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gh/users/add')}>{t('create')} {t('new')} {t('user')}</Button>
            </div>

            {/* Tab navigation */}
            <div className="mt-8">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">Select a tab</label>
                    <select
                        id="tabs"
                        name="tabs"
                        value={selectedRole!}
                        onChange={(e) => setSelectedRole(e.target.value)}
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 sm:text-sm"
                    >
                        <option value="All">All</option>
                        {roles.map((role) => (
                            <option key={role.rolename} value={role.rolename}>
                                {role.rolename}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                            <button
                                onClick={() => setSelectedRole('All')}
                                className={`flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium ${selectedRole === 'All'
                                    ? 'border-zinc-500 text-zinc-600 font-semibold'
                                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700'
                                    }`}
                            >
                                All
                            </button>
                            {roles.map((role) => (
                                <button
                                    key={role.rolename}
                                    onClick={() => setSelectedRole(role.rolename)}
                                    className={`flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium ${selectedRole === role.rolename
                                        ? 'border-zinc-500 text-zinc-600 font-semibold'
                                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700'
                                        }`}
                                >
                                    {role.rolename}
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            <ul>
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {!loading && !error && currentUsers.length === 0 && <p className='py-16 text-center'>No user found.</p>}
                {!loading && !error && currentUsers.map((user, index) => (
                    <li key={user.id}>
                        <Divider soft={index > 0} />
                        <div className="flex items-center justify-between">
                            <div className="flex gap-6 py-5">
                                <Avatar square initials={user.fullname[0]} className="bg-zinc-300 text-gray-700 size-12" />
                                <div className="">
                                    <div className="text-base/6 font-semibold">
                                        <Link href={`/gh/users/view/${user.id}`}>{user.fullname}</Link>
                                    </div>
                                    <div className="text-xs/6 text-zinc-600">
                                        {user.email ? user.email : 'Undefined email'}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Badge className="max-sm:hidden" color='lime'>
                                    {user.rolename.toUpperCase()}
                                </Badge>
                                <Dropdown>
                                    <DropdownButton plain aria-label="More options">
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                        <DropdownItem onClick={() => navigate(`/gh/users/view/${user.id}`)}>{t('view')}</DropdownItem>
                                        <DropdownItem onClick={() => navigate(`/gh/users/edit/${user.id}`)}>{t('edit')}</DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDeleteDialog(user.id)}>{t('delete')}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                totalResult={totalUsers}
                dataName={t('users')}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete User</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this user? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GHUsersPage;
