import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Badge } from '../../../../../components/Badge';
import { Button } from '../../../../../components/Button';
import { Divider } from '../../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../../components/Description-List';
import { Avatar } from '../../../../../components/Avatar';
import { TchPillowPatient } from '../../../../../models/TchPillowPatient';
import useTchPillowPatientService from '../../../../../hooks/useTchPillowPatient';
import dateFormat from 'dateformat';
import Loading from '../../../../../components/Loading';
import tchPillowPatientImagesService from '../../../../../services/tchpillowpatientimagesService';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../../components/Dialog'; // Import Dialog components
import { useTranslation } from 'react-i18next';

interface GetImagesResponse {
    images: Image[]; // Assuming images is an array of Image objects
}

interface Image {
    id: string;
    image_data: string;
    // Other fields
}

interface ImageData {
    id: number;
    image_data: string;
}

const TchPillowPatientIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { fetchPillowPatientById, error, loading } = useTchPillowPatientService();
    const [patient, setPatient] = useState<TchPillowPatient | null>(null);
    const [images, setImages] = useState<ImageData[]>([]);
    const [isOpen, setIsOpen] = useState(false); // State to control dialog visibility
    const [isDeleting, setIsDeleting] = useState(false); // State to indicate deletion in progress
    const [imageToDelete, setImageToDelete] = useState<ImageData | null>(null); // State to track the image to be deleted
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${t('view')} ${t('pillow_buyers')} | ${t('patient')}`
    }, []);

    useEffect(() => {
        if (!id) return;

        const fetchPatient = async () => {
            try {
                const patientData = await fetchPillowPatientById(id);
                setPatient(patientData);
            } catch (err) {
                console.error('Failed to fetch patient details:', err);
            }
        };

        const fetchPatientImage = async () => {
            try {
                const patientImageData = await tchPillowPatientImagesService.getPatientImages(id);
                setImages(patientImageData.Success || []);
            } catch (err) {
                console.error('Failed to fetch patient images:', err);
            }
        };

        fetchPatient();
        fetchPatientImage();
    }, [id]);

    const handleDeleteImageOnClick = (image: ImageData) => {
        setImageToDelete(image);
        setIsOpen(true); // Open the confirmation dialog
    };

    const handleDelete = async () => {
        if (!imageToDelete) return;

        setIsDeleting(true);
        try {
            await tchPillowPatientImagesService.deletePatientImage(imageToDelete.id);
            setImages(prevImages => prevImages.filter(image => image.id !== imageToDelete.id));
            setIsOpen(false);
        } catch (err) {
            console.error(`Failed to delete image with ID ${imageToDelete.id}:`, err);
        } finally {
            setIsDeleting(false);
            setImageToDelete(null);
        }
    };

    if (loading && !patient) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!patient) return <p className='py-16 text-center'>Patient not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/tch/products/pillows/patients" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    {t('pillow_buyers')}
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={patient.fullname[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{patient.fullname}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>{t('created_on')}</span><span>{dateFormat(patient.created_time!, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/tch/products/pillows/patients/edit/${patient.id.toString()}`)}>{t('edit')}</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>{t('profile')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('fullname')}</DescriptionTerm>
                    <DescriptionDetails>{patient.fullname}</DescriptionDetails>
                    <DescriptionTerm>{t('email')}</DescriptionTerm>
                    <DescriptionDetails>{patient.email || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('mobile_no')}</DescriptionTerm>
                    <DescriptionDetails>{patient.mobile_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('address')}</DescriptionTerm>
                    <DescriptionDetails>{patient.street_address || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('date_of_birth')}</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(patient.dob, "mmmm dd, yyyy") || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('height')}</DescriptionTerm>
                    <DescriptionDetails>{patient.height || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('weight')}</DescriptionTerm>
                    <DescriptionDetails>{patient.weight || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('gender')}</DescriptionTerm>
                    <DescriptionDetails>{patient.gender || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('purchase_quantity')}</DescriptionTerm>
                    <DescriptionDetails>{patient.purchase_quantity || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('remarks')}</DescriptionTerm>
                    <DescriptionDetails>{patient.remarks || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
            <div className="mt-12">
                <Subheading>{t('patient_information')}</Subheading>
                <Divider className="mt-4" />
                <img
                    src={'/images/PillowPatientRefer.png'}
                    alt="Patient Measurement Info"
                    className="w-full max-w-none rounded-xl ring-1 ring-gray-200 md:-ml-4 lg:-ml-0 mt-4"
                />
                <DescriptionList className='mt-5'>
                    <DescriptionTerm>{t('sagittal_suture')}</DescriptionTerm>
                    <DescriptionDetails>{patient.sagittal_suture || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('external_occipital_protuberance')}</DescriptionTerm>
                    <DescriptionDetails>{patient.external_occipital_protuberance || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('both_shoulders_align')}</DescriptionTerm>
                    <DescriptionDetails>{patient.both_shoulders_align || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>{t('patient_images')}</Subheading>
                <Divider className="mt-4" />
                <div className='grid grid-cols-3 md:-ml-4 lg:-ml-0 mt-4 gap-8'>
                    {images.length > 0 ? images.map((image: ImageData, i) => (
                        <div key={i} className=''>
                            <div className="flex flex-1 items-end justify-between text-sm py-4">
                                <p className="text-gray-500">{t('patient_images')} {i + 1}</p>
                                <div className="flex">
                                    <button
                                        type="button"
                                        className="font-medium text-red-600 hover:text-indigo-500"
                                        onClick={() => handleDeleteImageOnClick(image)}
                                    >
                                        {t('delete')}
                                    </button>
                                </div>
                            </div>
                            <img
                                className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                src={`data:image/jpeg;base64,${image.image_data}`}
                                alt={`Image ${i + 1}`}
                            />
                        </div>
                    )) : (
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            No patient image found.
                        </label>
                    )}
                </div>
            </div>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>{t('delete')}{t('patient_images')}</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this image? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TchPillowPatientIdPage;
