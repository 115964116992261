import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Textarea } from '../../../../../components/Textarea';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../../components/Loading';
import { CalendarIcon, ChevronLeftIcon } from '@heroicons/react/16/solid';
import { Avatar } from '../../../../../components/Avatar';
import { CourseParticipant } from '../../../../../models/GhCourseParticipants';
import dateFormat from 'dateformat';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../../components/Description-List';
import { useCourseParticipants } from '../../../../../hooks/useGhCourseParticipants';
import { CourseParticipantImages } from '../../../../../models/ImagesModel';
import { useTranslation } from 'react-i18next';

const GHViewCourseParticipantIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const [courseParticipant, setCourseParticipant] = useState<CourseParticipant | null>(null);
    const { fetchParticipantById, loading, error, fetchParticipantImagesById } = useCourseParticipants();
    const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string | null }>({});
    const [images, setImages] = useState<CourseParticipantImages | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('course_participants')}`
    }, []);

    useEffect(() => {
        const fetchCourseParticipantDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedCourseParticipant = await fetchParticipantById(Number(id));
                setCourseParticipant(fetchedCourseParticipant);
            } catch (err) {
                showErrorToast('Failed to fetch course details.');
            }
        };

        const fetchCertificate = async () => {
            try {
                const courseParticipantImages = await fetchParticipantImagesById(Number(id!));

                // Set images to the state directly, assuming the structure matches
                setImages({
                    certificate: courseParticipantImages.certificate,
                    certificate_2: courseParticipantImages.certificate_2,
                });
            } catch (err) {
                console.error('Failed to fetch certificate:', err);
            }
        };
        fetchCourseParticipantDetails();
        fetchCertificate();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!courseParticipant) return <p className='py-16 text-center'>Course not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gh/courses/participants" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    {t('course_participants')}
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={courseParticipant.fullname![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{courseParticipant.fullname}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>{t('created_on')}</span><span>{dateFormat(courseParticipant.created_time!, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/ gh / courses / participants / edit / ${id} `)}>{t('edit')}</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>{t('course_participant_info')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('fullname')}</DescriptionTerm>
                    <DescriptionDetails>{courseParticipant.fullname}</DescriptionDetails>
                </DescriptionList>
                <DescriptionList>
                    <DescriptionTerm>{t('course')}</DescriptionTerm>
                    <DescriptionDetails>{courseParticipant.course_name}</DescriptionDetails>
                </DescriptionList>
                <DescriptionList>
                    <DescriptionTerm>{t('mobile_no')}</DescriptionTerm>
                    <DescriptionDetails>{courseParticipant.mobile_no || "-"}</DescriptionDetails>
                </DescriptionList>
                <DescriptionList>
                    <DescriptionTerm>{t('email')}</DescriptionTerm>
                    <DescriptionDetails>{courseParticipant.email || "-"}</DescriptionDetails>
                </DescriptionList>
                <DescriptionList>
                    <DescriptionTerm>{t('completed_date')}</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(courseParticipant.complete_date?.toString(), "mmmm dd,yyyy")}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>{t('participant')}{t('certificate')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('certificate')}</DescriptionTerm>
                    <DescriptionDetails>{images?.certificate ? (
                        <div>
                            {
                                images.certificate && (
                                    <div>
                                        <img
                                            className="w-1/2 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.certificate}
                                            alt="Certificate"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<p>No images available</p>)}</DescriptionDetails>
                    <DescriptionTerm>{t('certificate_2')}</DescriptionTerm>
                    <DescriptionDetails>{images?.certificate_2 ? (
                        <div>
                            {
                                images.certificate_2 && (
                                    <div>
                                        <img
                                            className="w-1/2 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.certificate_2}
                                            alt="More Certificate"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<p>No images available</p>)}
                    </DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default GHViewCourseParticipantIdPage;
