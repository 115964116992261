import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../../services/userService';
import { User } from '../../../models/User';
import { Heading, Subheading } from '../../../components/Heading';
import { Divider } from '../../../components/Divider';
import { Input } from '../../../components/Input';
import { Textarea } from '../../../components/Textarea';
import { Select } from '../../../components/Select';
import { Button } from '../../../components/Button';
import showLoadingToast from '../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../hooks/useRoles';
import Loading from '../../../components/Loading';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface AddUser {
    username: string;
    password: string;
    confirmPassword?: string; // Optional as it's not part of the AddUser interface but used for validation
    role: bigint;
    referrer_id: bigint | null;
    fullname: string;
    email: string;
    mobile_no: string;
    street_address: string;
    agent_no: string;
    remarks: string;
}

const GHAddUserPage = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState<AddUser>({
        username: '',
        password: '',
        confirmPassword: '', // For confirming password
        role: BigInt(0),
        referrer_id: null,
        fullname: '',
        email: '',
        mobile_no: '',
        street_address: '',
        agent_no: '',
        remarks: '',
    });
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [fetchUserLoading, setFetchUserLoading] = useState(true);
    const [fetchUserError, setFetchUserError] = useState<string | null>(null);
    const { roles, loading, error } = useRoles();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('create')} ${t('new')} ${t('user')}`
    }, []);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const users = await UserService.getAllUsers();
                setAllUsers(users);
                if (users.length > 0) {
                    setUser(prev => ({ ...prev, referrer_id: users[0].id }));
                }
            } catch (err) {
                setFetchUserError('Failed to fetch users.');
            } finally {
                setFetchUserLoading(false);
            }
        };

        fetchAllUsers();
    }, []);

    useEffect(() => {
        if (roles.length > 0) {
            setUser(prev => ({ ...prev, role: roles[0].id }));
        }
    }, [roles]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        if (!user.fullname) {
            showErrorToast('Full name is required');
            return false;
        }
        if (!user.username) {
            showErrorToast('Username is required');
            return false;
        }
        if (!user.password) {
            showErrorToast('Password is required');
            return false;
        }
        if (user.password !== user.confirmPassword) {
            showErrorToast('Passwords do not match');
            return false;
        }
        if (user.role === BigInt(0)) {
            showErrorToast('Role is required');
            return false;
        }
        if (user.referrer_id === null) {
            showErrorToast('Referrer is required');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const userToSubmit = {
                ...user,
                role: user.role.toString(),
                referrer_id: user.referrer_id!.toString(),
            };
            const toastId = showLoadingToast(`Creating ${user.fullname} ...`);
            const res = await UserService.addUser(userToSubmit);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/gh/users');
                showSuccessToast(`${user.fullname} added successfully`);
            } else {
                showErrorToast(`Error creating user: ${res.message}`);
            }
        } catch (err) {
            showErrorToast(`Failed to add user: ${err}`);
        }
    };

    if (fetchUserLoading || loading) return <Loading />;
    if (fetchUserError || error) return <p>{error}</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{`${t('create')} ${t('new')}${t('user')}`}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!user.username || !user.password || !user.confirmPassword || !user.email || !user.role || !user.referrer_id}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('profile')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('profile_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('fullname')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={user.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('email')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={user.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('mobile_no')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={user.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('street_address')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Street Address"
                                        name="street_address"
                                        value={user.street_address?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('agent_no')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Agent No"
                                        name="agent_no"
                                        value={user.agent_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('remarks')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={user.remarks?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='space-y-12'>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('account_information')}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                {t('account_information_description')}
                            </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('username')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="Username"
                                            name="username"
                                            value={user.username}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('password')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            type="password"
                                            aria-label="Password"
                                            name="password"
                                            value={user.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('confirm_password')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            type="password"
                                            aria-label="Confirm Password"
                                            name="confirmPassword"
                                            value={user.confirmPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('role')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            aria-label="Role"
                                            name="role"
                                            value={user.role.toString()}
                                            onChange={handleChange}
                                        >
                                            {roles.map(role => (
                                                <option key={role.id.toString()} value={role.id.toString()}>
                                                    {role.rolename}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('referrer')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            aria-label="Referrer"
                                            name="referrer_id"
                                            value={user.referrer_id?.toString() || ''}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select a Referrer</option>
                                            {allUsers.map(user => (
                                                <option key={user.id.toString()} value={user.id.toString()}>
                                                    {user.fullname}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 my-6">
                        <Button type="reset" plain onClick={() => navigate(-1)}>
                            {t('cancel')}
                        </Button>
                        <Button type="submit" className='' disabled={!user.username || !user.password || !user.confirmPassword || !user.email || !user.role || !user.referrer_id}>{t('save')}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default GHAddUserPage;
