'use client'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Avatar } from '../../components/Avatar'
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../../components/Dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../../components/Navbar'
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarHeading,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from '../../components/Sidebar'
import { SidebarLayout } from '../../components/SidebarLayout'
import {
    ArrowRightStartOnRectangleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    Cog8ToothIcon,
    LightBulbIcon,
    PlusIcon,
    ShieldCheckIcon,
    UserCircleIcon,
    LanguageIcon,
    ChevronRightIcon,
    CheckIcon,
    BellIcon,
    QueueListIcon,
    AcademicCapIcon,
    UsersIcon,
    LifebuoyIcon,
} from '@heroicons/react/16/solid'
import {
    Cog6ToothIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    SparklesIcon,
    Square2StackIcon,
    TicketIcon,
    UserGroupIcon,
    RectangleStackIcon,
} from '@heroicons/react/20/solid'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/userContext'
import { Divider } from '../../components/Divider'
import showLoadingToast from '../../components/Toasts/ShowLoadingToast'
import { SuperAdminProvider } from '../../provider/superadminProvider'
import { useTranslation } from 'react-i18next';

function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {

    const navigate = useNavigate(); // Initialize the navigate function
    const { logout } = useContext(UserContext);
    const { t } = useTranslation();

    // onClick handler that matches the expected type
    function logoutClick(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
        event.preventDefault();
        showLoadingToast("Signing out...", 100);
        setTimeout(() => {
            logout(navigate); // Call the logout function from context
        }, 1000)
    }

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem onClick={() => navigate(`/gh/myaccount/view`)}>
                <UserCircleIcon />
                <DropdownLabel>{t('ghsidebar_myaccount')}</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />

            <DropdownItem onClick={logoutClick}>
                <ArrowRightStartOnRectangleIcon />
                <DropdownLabel>{t('ghsidebar_signout')}</DropdownLabel>
            </DropdownItem>
        </DropdownMenu>
    )
}

function LanguageDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language || 'en');

    const changeLanguage = (lng: string) => {
        showLoadingToast("Changing language...", 100)
        setTimeout(() => {
            setLanguage(lng);
            i18n.changeLanguage(lng);
        }, 1000)
    };

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem onClick={() => changeLanguage('en')} className={language === 'en' ? 'font-bold' : 'font-normal'}>
                <DropdownLabel>
                    English
                </DropdownLabel>
                {(language === 'en') && <CheckIcon />}
            </DropdownItem>
            <DropdownItem onClick={() => changeLanguage('cn')} className={language === 'cn' ? 'font-bold' : 'font-normal'}>
                <DropdownLabel>
                    中文
                </DropdownLabel>
                {(language === 'cn') && <CheckIcon />}
            </DropdownItem>
        </DropdownMenu>
    );
}

function CourseSidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();

    // Toggle the expand/collapse state
    const toggleExpand = () => setIsExpanded(!isExpanded);

    // Detect if current route is a course-related page and expand sidebar accordingly
    useEffect(() => {
        if (pathname.includes('/gh/courses/types') || pathname.includes('/gh/courses/participants')) {
            setIsExpanded(true); // Ensure it's expanded if on these routes
        }
    }, [pathname]);

    return (
        <div>
            <SidebarItem onClick={toggleExpand} current={pathname.includes('/gh/courses')}>
                <AcademicCapIcon />
                <SidebarLabel>{t('ghsidebar_courses')}</SidebarLabel>
                {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </SidebarItem>

            {isExpanded && (
                <div className="ml-6 py-2">
                    <SidebarItem onClick={() => navigate('/gh/courses/types')} current={pathname.includes('/gh/courses/types')}>
                        <Square2StackIcon />
                        <SidebarLabel>{t('ghsidebar_courses_types')}</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate('/gh/courses/participants')} current={pathname.includes('/gh/courses/participants')}>
                        <UsersIcon />
                        <SidebarLabel>{t('ghsidebar_courses_participants')}</SidebarLabel>
                    </SidebarItem>
                </div>
            )}
        </div>
    );
}

function ProfileDropdown() {
    const location = useLocation();
    const pathname = location.pathname;
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    const tchOnClick = () => {
        showLoadingToast(`Switching Team...`, 1000);
        setTimeout(() => {
            navigate('/tch/dashboard');
        }, 2000);
    }

    if (currentUser?.role!.toString() !== "1" && currentUser?.role!.toString() !== "2") {
        return (
            <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                <DropdownItem onClick={tchOnClick}>
                    <Avatar slot="icon" initials="TCH" className="bg-purple-500 text-white" />
                    <DropdownLabel>TCH Malaysia</DropdownLabel>
                </DropdownItem>
            </DropdownMenu>
        )
    } else {
        return (
            <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                <DropdownItem>
                    <Avatar slot="icon" initials="GH" className="bg-red-800 text-white" />
                    <DropdownLabel>Grateful Healthcare Malaysia</DropdownLabel>
                </DropdownItem>
                <DropdownItem onClick={tchOnClick}>
                    <Avatar slot="icon" initials="TCH" className="bg-purple-500 text-white" />
                    <DropdownLabel>TCH Malaysia</DropdownLabel>
                </DropdownItem>
            </DropdownMenu>
        )
    }
}

export function GHLayout() {
    const location = useLocation();
    const pathname = location.pathname;
    const { currentUser } = useContext(UserContext);
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language || 'en');
    const { t } = useTranslation();
    const navigate = useNavigate(); // Use navigate hook

    // Define the sidebar items based on the role
    const renderSidebarItems = () => {
        switch (currentUser?.rolename) {
            case 'Superadmin':
                return (
                    <>
                        <SidebarItem onClick={() => navigate('/gh/dashboard')} current={pathname.includes('gh/dashboard')}>
                            <HomeIcon />
                            <SidebarLabel>{t('ghsidebar_dashboard')}</SidebarLabel>
                        </SidebarItem>
                        <SidebarItem onClick={() => navigate('/gh/users')} current={pathname.includes('/gh/users')}>
                            <UserGroupIcon />
                            <SidebarLabel>{t('ghsidebar_users')}</SidebarLabel>
                        </SidebarItem>
                        <SidebarItem onClick={() => navigate('/gh/braces/patients')} current={pathname.includes('/gh/braces')}>
                            <LifebuoyIcon />
                            <SidebarLabel>{t('ghsidebar_patients')}</SidebarLabel>
                        </SidebarItem>
                        <CourseSidebar />
                    </>
                );
            case 'Staff':
                return (
                    <>
                        <SidebarItem onClick={() => navigate('/gh/dashboard')} current={pathname.includes('gh/dashboard')}>
                            <HomeIcon />
                            <SidebarLabel>{t('ghsidebar_dashboard')}</SidebarLabel>
                        </SidebarItem>
                        <SidebarItem onClick={() => navigate('/gh/braces/patients')} current={pathname.includes('/gh/braces')}>
                            <QueueListIcon />
                            <SidebarLabel>{t('ghsidebar_braces')}</SidebarLabel>
                        </SidebarItem>
                        <CourseSidebar />
                    </>
                );
            default:
                return null; // Or render a message, or redirect
        }
    };


    const tchOnClick = () => {
        showLoadingToast(`Switching Team...`, 1000);
        setTimeout(() => {
            navigate('/tch/dashboard');
        }, 2000);
    };

    return (
        <SidebarLayout
            navbar={
                <Navbar>
                    <NavbarSpacer />
                    <NavbarSection>
                        <Dropdown>
                            <DropdownButton as={NavbarItem}>
                                <Avatar square initials={currentUser ? currentUser.fullname[0] : "GH"} className="bg-gray-300 text-gray-700" />
                            </DropdownButton>
                            <AccountDropdownMenu anchor="bottom end" />
                        </Dropdown>
                    </NavbarSection>
                </Navbar>
            }
            sidebar={
                <Sidebar>
                    <SidebarHeader>
                        <Dropdown>
                            <DropdownButton as={SidebarItem}>
                                <Avatar slot="icon" initials={pathname.includes('/gh') ? 'GH' : 'TCH'} className="bg-red-800 text-white" />
                                <SidebarLabel>{pathname.includes('/gh') ? 'Grateful Healthcare' : 'TCH Malaysia'}</SidebarLabel>
                                <ChevronDownIcon />
                            </DropdownButton>
                            <ProfileDropdown />
                        </Dropdown>
                    </SidebarHeader>

                    <SidebarBody>
                        <SidebarSection>
                            {renderSidebarItems()}
                        </SidebarSection>

                        <SidebarSpacer />

                        <SidebarSection>
                            <SidebarItem onClick={() => navigate('#')}>
                                <QuestionMarkCircleIcon />
                                <SidebarLabel>{t('support')}</SidebarLabel>
                            </SidebarItem>
                            <SidebarItem onClick={() => navigate('#')}>
                                <SparklesIcon />
                                <SidebarLabel>{t('changelog')}</SidebarLabel>
                            </SidebarItem>
                            <Dropdown>
                                <DropdownButton as={SidebarItem}>
                                    <LanguageIcon />
                                    <SidebarLabel>{t('change_language')} {t(i18n.language)}</SidebarLabel>
                                    <ChevronUpIcon />
                                </DropdownButton>
                                <LanguageDropdownMenu anchor="top start" />
                            </Dropdown>
                        </SidebarSection>
                    </SidebarBody>

                    <SidebarFooter className="max-lg:hidden">
                        <Dropdown>
                            <DropdownButton as={SidebarItem}>
                                <span className="flex min-w-0 items-center gap-3">
                                    <Avatar square initials={currentUser ? currentUser.fullname[0] : "GH"} className="bg-gray-300 text-gray-700 size-10" />
                                    <span className="min-w-0">
                                        <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{currentUser ? currentUser.fullname : "Guest"}</span>
                                        <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                            {currentUser ? currentUser.rolename || "Undefined Role" : "Undefined Role"}
                                        </span>
                                    </span>
                                </span>
                                <ChevronUpIcon />
                            </DropdownButton>
                            <AccountDropdownMenu anchor="top start" />
                        </Dropdown>
                    </SidebarFooter>
                </Sidebar>
            }
        >
            {/* Main Content */}
            <main className="mx-auto max-w-screen-2xl text-zinc-950 antialiased dark:text-white dark:lg:bg-zinc-950">
                <Outlet />
            </main>
        </SidebarLayout>
    );
}