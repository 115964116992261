import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGHBraceData } from '../../../../../hooks/useGhBraceData';
import { GHBraceData } from '../../../../../models/GhBraceData';
import { BraceDataImages } from '../../../../../models/ImagesModel';
import { CalendarIcon, ChevronLeftIcon } from '@heroicons/react/16/solid';
import { Avatar } from '../../../../../components/Avatar';
import { Heading, Subheading } from '../../../../../components/Heading';
import dateFormat from 'dateformat';
import { Button } from '../../../../../components/Button';
import { Divider } from '../../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../../components/Description-List';
import Loading from '../../../../../components/Loading';
import { Text } from '../../../../../components/Text';
import { useTranslation } from 'react-i18next';

const ViewBraceDataPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { fetchDataById, fetchDataImagesById, loading, error } = useGHBraceData();

    const [braceData, setBraceData] = useState<GHBraceData | null>(null);
    const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string | null }>({});
    const [images, setImages] = useState<BraceDataImages | null>(null);
    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${t('view')} ${t('brace_data')}`
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const existingData = await fetchDataById(Number(id!));
            setBraceData(existingData);

            const previews: { [key: string]: string | null } = {};
            Object.keys(existingData).forEach((key) => {
                const typedKey = key as keyof GHBraceData;
                const value = existingData[typedKey];

                if (typeof value === 'string' && value.startsWith('data:image/')) {
                    previews[typedKey] = value;
                } else {
                    previews[typedKey] = null;
                }
            });

            setImagePreviews(previews);
        };

        // Fetch brace data images
        const fetchBraceDataImages = async () => {
            try {
                const braceDataImages = await fetchDataImagesById(Number(id!));

                // Set images to the state directly, assuming the structure matches
                setImages({
                    scan_data: braceDataImages.scan_data,
                    patient_images_xray: braceDataImages.patient_images_xray,
                    patient_images_back: braceDataImages.patient_images_back,
                    patient_images_front: braceDataImages.patient_images_front,
                    patient_images_left: braceDataImages.patient_images_left,
                    patient_images_right: braceDataImages.patient_images_right,
                    patient_images_forwardbending: braceDataImages.patient_images_forwardbending,
                    visitxray_pic_xray: braceDataImages.visitxray_pic_xray,
                    visitxray_pic_brace_back: braceDataImages.visitxray_pic_brace_back,
                    visitxray_pic_brace_front: braceDataImages.visitxray_pic_brace_front,
                });
            } catch (err) {
                console.error('Failed to fetch patient images:', err);
            }
        };

        fetchData();
        fetchBraceDataImages();
    }, [id]);

    const normalizeStringToKey = (str: string) => {
        // Convert spaces to underscores, make lowercase, and remove any other special characters.
        return str
            .toLowerCase()
            .replace(/\s+/g, '_')  // Replace spaces with underscores
            .replace(/[^\w\s]/g, '');  // Remove non-alphanumeric characters
    };

    if (loading && !braceData) return <div className="py-32 text-center"><Loading /><p className='py-12'>Loading Patient's Brace Data...</p></div>;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!braceData) return <p className="py-16 text-center">Brace data not found. Please try again.</p>;

    return (
        <div>
            <div className="max-lg:hidden">
                <Text onClick={() => { navigate(-1) }} className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    {t("brace_patients")}
                </Text>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={braceData.fullname![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{braceData.fullname!}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                        <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                        <span className='pr-1 text-zinc-400'>{t('created_on')}</span><span>{dateFormat(braceData.created_time!, "mmmm dd, yyyy")}</span>
                    </span>
                    <div className='flex flex-wrap items-end justify-between gap-4'>
                        <Button onClick={() => navigate(`/gh/braces/data/edit/${id}`)}>{t('edit')}</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>{t('brace_data')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>

                    <DescriptionTerm>{t('date')}</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(braceData.date!, "mmmm dd, yyyy") || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('fullname')}</DescriptionTerm>
                    <DescriptionDetails>{braceData.fullname}</DescriptionDetails>
                    <DescriptionTerm>{t('patient_id')}</DescriptionTerm>
                    <DescriptionDetails>{braceData.patient_id!.toString()}</DescriptionDetails>
                    <DescriptionTerm>{t('brace')}</DescriptionTerm>
                    <DescriptionDetails>{braceData.brace_priority ? t(normalizeStringToKey(braceData.brace_priority)) : "-"}</DescriptionDetails>

                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>{t('patient_information')}</Subheading>
                <Divider className="mt-4" />

                <div className='mt-4 grid grid-cols-1 gap-10 md:grid-cols-2 items-center'>
                    <img
                        src={'/images/BraceDataRefer1.png'}
                        alt="Patient Measurement Info"
                        className="md:pt-20 bg-zinc-300 w-full max-w-none rounded-xl ring-1 ring-gray-200"
                    />
                    <div className='grid grid-rows-6 grid-flow-col gap-6 divide-y divide-zinc-100'>
                        <div className='grid grid-cols-4'>
                            <div></div>
                            <Text className='font-bold uppercase text-black'>{t('size')}</Text>
                            <Text className='font-bold uppercase text-black'>{t('ll')}</Text>
                            <Text className='font-bold uppercase text-black'>{t('ap')}</Text>
                        </div>
                        <div className='grid grid-cols-4 pt-4'>
                            <Text className='font-bold uppercase text-black'>{t('armpit')}</Text>
                            <Text>{braceData.armpit_size}</Text>
                            <Text>{braceData.armpit_ll}</Text>
                            <Text>{braceData.armpit_ap}</Text>
                        </div>
                        <div className='grid grid-cols-4 pt-4'>
                            <Text className='font-bold uppercase text-black'>{t('xiphoid')}</Text>
                            <Text>{braceData.xiphoid_size}</Text>
                            <Text>{braceData.xiphoid_ll}</Text>
                            <Text>L:{braceData.xiphoid_ap_left} R:{braceData.xiphoid_ap_right}</Text>
                        </div>
                        <div className='grid grid-cols-4 pt-4'>
                            <Text className='font-bold uppercase text-black'>{t('waist')}</Text>
                            <Text>{braceData.waist_size}</Text>
                            <Text>{braceData.waist_ll}</Text>
                            <Text>{braceData.waist_ap}</Text>
                        </div>
                        <div className='grid grid-cols-4 pt-4'>
                            <Text className='font-bold uppercase text-black'>{t('spine_height')}</Text>
                            <Text>{braceData.spineheight_size}</Text>
                            <Text>{braceData.spineheight_ll}</Text>
                            <Text>{braceData.spineheight_ap}</Text>
                        </div>
                        <div className='grid grid-cols-4 pt-4'>
                            <Text className='font-bold uppercase text-black'>{t('trocanter')}</Text>
                            <Text>{braceData.trocanter_size}</Text>
                            <Text>{braceData.trocanter_ll}</Text>
                            <Text>{braceData.trocanter_ap}</Text>
                        </div>
                    </div>
                </div>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Spine Width</DescriptionTerm>
                    <DescriptionDetails>{braceData.spine_width}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>Additional Measurement Information</Subheading>
                <Divider className="mt-4" />
                <div className='mt-4 grid grid-cols-1 gap-10 md:grid-cols-2 items-center'>
                    <img
                        src={'/images/BraceDataRefer2.png'}
                        alt="Patient Measurement Info"
                        className="w-full max-w-none rounded-xl ring-1 ring-gray-200 md:-ml-4 lg:-ml-0 mt-4"
                    />
                    <DescriptionList>
                        <DescriptionTerm>{t('parameter')} 1</DescriptionTerm>
                        <DescriptionDetails>{braceData.add2_parameter_1}</DescriptionDetails>
                        <DescriptionTerm>{t('parameter')} 2</DescriptionTerm>
                        <DescriptionDetails>{braceData.add2_parameter_2}</DescriptionDetails>
                        <DescriptionTerm>{t('parameter')}  3</DescriptionTerm>
                        <DescriptionDetails>{braceData.add2_parameter_3}</DescriptionDetails>
                        <DescriptionTerm>{t('parameter')} 4</DescriptionTerm>
                        <DescriptionDetails>{braceData.add2_parameter_4}</DescriptionDetails>
                        <DescriptionTerm>{t('parameter')} 5</DescriptionTerm>
                        <DescriptionDetails>{braceData.add2_parameter_5}</DescriptionDetails>
                    </DescriptionList>
                </div>


            </div>

            <div className="mt-12">
                <Subheading>{t('additional_brace_info')} </Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('additional_brace_info')} </DescriptionTerm>
                    <DescriptionDetails>{braceData.additional_brace_info || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>{t('patient_images')} </Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>X-Ray Image</DescriptionTerm>
                    <DescriptionDetails>{images?.patient_images_xray ? (
                        <div>
                            {
                                images.patient_images_xray && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.patient_images_xray}
                                            alt="Patient Image X-ray"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>Back Image</DescriptionTerm>
                    <DescriptionDetails>{images?.patient_images_back ? (
                        <div>
                            {
                                images.patient_images_back && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.patient_images_back}
                                            alt="Patient Image Back"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>Front Image</DescriptionTerm>
                    <DescriptionDetails>{images?.patient_images_front ? (
                        <div>
                            {
                                images.patient_images_front && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.patient_images_front}
                                            alt="Patient Image Front"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>Left Image</DescriptionTerm>
                    <DescriptionDetails>{images?.patient_images_left ? (
                        <div>
                            {
                                images.patient_images_left && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.patient_images_left}
                                            alt="Patient Image Left"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>Right Image</DescriptionTerm>
                    <DescriptionDetails>{images?.patient_images_right ? (
                        <div>
                            {
                                images.patient_images_right && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.patient_images_right}
                                            alt="Patient Image Right"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>Forward Bending Image</DescriptionTerm>
                    <DescriptionDetails>{images?.patient_images_forwardbending ? (
                        <div>
                            {
                                images.patient_images_back && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.patient_images_forwardbending}
                                            alt="Patient Image Forward Bending"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>{t('visit_with_xray')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('cobb_angle_thoracic')}</DescriptionTerm>
                    <DescriptionDetails>{braceData.visitxray_cobb_th || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('cobb_angle_lumbar')}</DescriptionTerm>
                    <DescriptionDetails>{braceData.visitxray_cobb_i || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('picture_with_brace_xray')}</DescriptionTerm>
                    <DescriptionDetails>{images?.visitxray_pic_xray ? (
                        <div>
                            {
                                images.visitxray_pic_xray && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.visitxray_pic_xray}
                                            alt="Picture with brace X-Ray"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>{t('picture_with_brace_back')}</DescriptionTerm>
                    <DescriptionDetails>{images?.visitxray_pic_brace_back ? (
                        <div>
                            {
                                images.visitxray_pic_brace_back && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.visitxray_pic_brace_back}
                                            alt="Picture with brace Back"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                    <DescriptionTerm>{t('picture_with_brace_front')}</DescriptionTerm>
                    <DescriptionDetails>{images?.visitxray_pic_brace_front ? (
                        <div>
                            {
                                images.visitxray_pic_brace_front && (
                                    <div>
                                        <img
                                            className="w-1/4 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.visitxray_pic_brace_front}
                                            alt="Picture with brace Front"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<Text>No images available</Text>)}</DescriptionDetails>
                </DescriptionList>
            </div>
        </div>
    );
};

export default ViewBraceDataPage;
