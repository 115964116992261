// frontend/services/GHBracePatientService.ts

import axios from 'axios';
import { GHBracePatient } from '../models/GhBracePatient';

// Define the base API URL (update with your actual API URL)
const API_BASE_URL = process.env.REACT_APP_SERVER_URL + '/ghbracepatients';


// Create an instance of axios with default settings
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const GHBracePatientService = {

    getAllPatients: async (): Promise<GHBracePatient[]> => {
        try {
            const response = await api.get('/getAllBracePatients');
            return response.data;
        } catch (error) {
            console.error('Error fetching all patients:', error);
            throw error;
        }
    },

    getThreePatients: async (): Promise<GHBracePatient[]> => {
        try {
            const response = await api.get('/getThreeBracePatients');
            return response.data;
        } catch (error) {
            console.error('Error fetching three patients:', error);
            throw error;
        }
    },

    getPatientById: async (id: number): Promise<GHBracePatient> => {
        try {
            const response = await api.get(`/getBracePatientById/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching patient with ID ${id}:`, error);
            throw error;
        }
    },

    createPatient: async (patientData: Omit<GHBracePatient, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const response = await api.post('/add', patientData);
            return response.data;
        } catch (error) {
            console.error('Error creating patient:', error);
            throw error;
        }
    },

    updatePatient: async (id: number, patientData: Omit<GHBracePatient, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const response = await api.put(`/update/${id}`, patientData);
            return response.data;
        } catch (error) {
            console.error(`Error updating patient with ID ${id}:`, error);
            throw error;
        }
    },

    deletePatient: async (id: number): Promise<void> => {
        try {
            await api.delete(`/delete/${id}`);
        } catch (error) {
            console.error(`Error deleting patient with ID ${id}:`, error);
            throw error;
        }
    }
};

export default GHBracePatientService;
