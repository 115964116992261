import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../../components/Dropdown';
import { Badge } from '../../../../components/Badge';
import { Link } from '../../../../components/Link';
import { Button } from '../../../../components/Button';
import { Heading, Subheading } from '../../../../components/Heading';
import { Input, InputGroup } from '../../../../components/Input';
import { Divider } from '../../../../components/Divider';
import { courseParticipantService } from '../../../../services/ghcourseparticipantsService'; // Import the Participantsservice
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import Pagination from '../../../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import dateFormat from 'dateformat';
import { useCourseParticipants } from '../../../../hooks/useGhCourseParticipants';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../components/Text';

const GHCourseParticipantsPage: React.FC = () => {
    const { participants, loading, error, totalCourseParticipants, deleteParticipant, fetchParticipants } = useCourseParticipants(); // Custom hook using Participantsservice
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [currentPage, setCurrentPage] = useState<number>(1); // State to track the current page
    const [participantssPerPage] = useState<number>(10); // Number of Participantss per page
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedParticipantsId, setSelectedParticipantsId] = useState<string | null>(null); // ID of the Participants to be deleted
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    // Filter CourseTypes based on the search query
    const filteredParticipants = Array.isArray(participants)
        ? participants.filter((participant) => {
            const matchesSearch = participant.fullname?.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesStartDate = !filterStartDate || new Date(participant.created_time!) >= new Date(filterStartDate);
            const matchesEndDate = !filterEndDate || new Date(participant.created_time!) <= new Date(filterEndDate);
            const matchesYear = !filterYear || new Date(participant.created_time!).getFullYear().toString() === filterYear;

            return matchesSearch && matchesStartDate && matchesEndDate && matchesYear;
        })
        : [];

    useEffect(() => {
        document.title = `${t('course_participants')}`
    }, []);

    // Open the delete dialog with the selected Participants's ID
    const handleOpenDeleteDialog = (participantsId: string) => {
        setSelectedParticipantsId(participantsId);
        setIsOpen(true);
    };

    // Handle delete Participants
    const handleDelete = async () => {
        if (!selectedParticipantsId) return;

        setIsDeleting(true);
        try {
            await deleteParticipant(Number(selectedParticipantsId)); // Call the delete API
            fetchParticipants(); // Refresh the Participants list
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete participants:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>{t('course_participants')}</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder={`${t('search')} ${t('participant')}${String.fromCharCode(8230)}`}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gh/courses/participants/add')}>{t('create')} {t('new')} {t('participant')}</Button>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>{t('filters_and_sorting')}</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-6 sm:grid-cols-1'>
                    <div>
                        <Text>{t('from_date')}</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>{t('to_date')}</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>{t('by_year')}</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                        }}>
                            {t('reset')}{t('filter')}
                        </Button>
                    </div>
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {!loading && !error && filteredParticipants.length === 0 && <p className='py-16 text-center'>No participant found.</p>}
                {!loading && !error && filteredParticipants.map((participants, index) => (
                    <li key={participants.id}>
                        <Divider soft={index > 0} />
                        <div className="flex items-center justify-between">
                            <div className="flex gap-6 py-5">
                                <Avatar square initials={participants.fullname![0]} className="bg-zinc-300 text-gray-700 size-12" />
                                <div className="">
                                    <div className="text-base/6 font-semibold">
                                        <Link href={`/gh/courses/participants/view/${participants.id}`}>{participants.fullname}</Link>
                                    </div>
                                    <div className="text-xs/6 text-zinc-600">
                                        {participants.email ? participants.email : "-"}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Badge className="max-sm:hidden" color='lime'>
                                    {participants.course_name}
                                </Badge>
                                <Dropdown>
                                    <DropdownButton plain aria-label="More options">
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                        <DropdownItem onClick={() => navigate(`/gh/courses/participants/view/${participants.id}`)}>{t('view')}</DropdownItem>
                                        <DropdownItem onClick={() => navigate(`/gh/courses/participants/edit/${participants.id}`)}>{t('edit')}</DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDeleteDialog(participants.id.toString())}>{t('delete')}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Participants</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this course? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GHCourseParticipantsPage;
