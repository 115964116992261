import React, { ChangeEvent, useEffect, useState } from 'react';
import { useGHBraceData } from '../../../../../hooks/useGhBraceData';
import { GHBraceData } from '../../../../../models/GhBraceData';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Button } from '../../../../../components/Button';
import { Divider } from '../../../../../components/Divider';
import { Radio, RadioField, RadioGroup } from '../../../../../components/Radio';
import { Input } from '../../../../../components/Input';
import { Label } from '../../../../../components/Fieldset';
import { Textarea } from '../../../../../components/Textarea';
import { useNavigate, useParams } from 'react-router-dom';
import useGHBracePatients from '../../../../../hooks/useGhBracePatient';
import Select from 'react-select';
import { GHBracePatient } from '../../../../../models/GhBracePatient';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import { BraceDataImages } from '../../../../../models/ImagesModel';
import Loading from '../../../../../components/Loading';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../components/Text';
import toast from 'react-hot-toast';

// Utility function to format date to yyyy-MM-dd based on local time
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);

    // Get the year, month, and day using local time
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
};

// Create a function to convert Base64 to Blob
const base64ToBlob = (base64: string, type = 'application/octet-stream') => {
    if (!base64) return null; // Return null if the base64 string is empty

    // Check if the base64 string has a prefix and strip it
    const base64Regex = /^data:(.+?);base64,(.+)$/;
    const matches = base64.match(base64Regex);

    if (matches) {
        base64 = matches[2]; // Take the actual base64 part
    }

    // Ensure padding for base64 string
    while (base64.length % 4 !== 0) {
        base64 += "=";
    }

    try {
        const byteCharacters = atob(base64); // Decode Base64 to binary string
        const byteNumbers = new Uint8Array(byteCharacters.length); // Create a byte array

        // Fill the byte array with binary data
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Create and return a new Blob object from the byte array
        return new Blob([byteNumbers], { type: type });
    } catch (error) {
        console.error("Error decoding Base64 string:", error);
        return null; // Return null if decoding fails
    }
};

const UpdateBraceDataPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { fetchDataById, fetchDataImagesById, updateData, error, loading, createData } = useGHBraceData();
    const navigate = useNavigate();
    const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
    const [selectedPatient, setSelectedPatient] = useState<any | null>(null);
    const { t } = useTranslation();

    // Helper function to get today's date in yyyy-mm-dd format
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    useEffect(() => {
        document.title = `${t('edit')} ${t('brace_data')}`
    }, []);

    const [braceData, setBraceData] = useState<Omit<GHBraceData, 'id' | 'created_time' | 'updated_time'>>({
        patient_id: BigInt(0),
        date: getTodayDate(),
        brace_priority: 'Primary Brace',
        armpit_size: '',
        armpit_ll: '',
        armpit_ap: '',
        xiphoid_size: '',
        xiphoid_ll: '',
        xiphoid_ap_left: '',
        xiphoid_ap_right: '',
        waist_size: '',
        waist_ll: '',
        waist_ap: '',
        spineheight_size: '',
        spineheight_ll: '',
        spineheight_ap: '',
        trocanter_size: '',
        trocanter_ll: '',
        trocanter_ap: '',
        spine_width: '',
        add2_parameter_1: '',
        add2_parameter_2: '',
        add2_parameter_3: '',
        add2_parameter_4: '',
        add2_parameter_5: '',
        additional_brace_info: '',
        visitxray_cobb_th: '',
        visitxray_cobb_i: '',
        scan_data: null,
        patient_images_xray: null,
        patient_images_back: null,
        patient_images_front: null,
        patient_images_left: null,
        patient_images_right: null,
        patient_images_forwardbending: null,
        visitxray_pic_xray: null,
        visitxray_pic_brace_back: null,
        visitxray_pic_brace_front: null,
    });

    const [images, setImages] = useState<BraceDataImages | null>(null);
    const [newImages, setNewImages] = useState({
        scan_data: braceData.scan_data,
        patient_images_xray: braceData.patient_images_xray,
        patient_images_back: braceData.patient_images_back,
        patient_images_front: braceData.patient_images_front,
        patient_images_left: braceData.patient_images_left,
        patient_images_right: braceData.patient_images_right,
        patient_images_forwardbending: braceData.patient_images_forwardbending,
        visitxray_pic_xray: braceData.visitxray_pic_xray,
        visitxray_pic_brace_back: braceData.visitxray_pic_brace_back,
        visitxray_pic_brace_front: braceData.visitxray_pic_brace_front,
    });

    // Handle input changes for text and date fields
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBraceData({
            ...braceData,
            [e.target.name]: e.target.value,
        });
    };

    // Handle file inputs
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const { files } = e.target;
        if (files && files[0]) {
            setFileNames((prevNames) => ({
                ...prevNames,
                [inputName]: files[0].name,
            }));
            setBraceData((prevData) => ({
                ...prevData,
                [inputName]: files[0],
            }));
            setNewImages((prev) => ({
                ...prev, [inputName]: files[0]
            }));
        }
    };

    // Handle brace priority change
    const handleBracePriorityChange = (value: string) => {
        setBraceData((prev) => ({ ...prev, brace_priority: value }));
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!braceData) {
            return;
        }
        if (!braceData.date || !braceData.patient_id || !braceData.brace_priority) {
            showErrorToast(`Please fill in all required fields before submit`);
            return;
        };

        try {
            const toastId = showLoadingToast(`Updating ${braceData!.fullname} ...`);
            const res = await updateData(Number(id!), braceData, newImages);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate(-1);
                showSuccessToast(`${braceData!.fullname}'s brace data updated successfully`);
            } else {
                showErrorToast(`Error updating brace patient data`);
            }
        } catch (err) {
            showErrorToast(`Failed to update patient's brace data: ${err}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const existingData = await fetchDataById(Number(id));
            // Convert date fields to the correct format before setting the state
            if (existingData.date) {
                existingData.date = formatDate(existingData.date);
            }
            setBraceData(existingData);
        };

        const fetchBraceDataImages = async () => {
            const braceDataImages = await fetchDataImagesById(Number(id!));
            // Set images to the state directly, assuming the structure matches
            setImages({
                scan_data: braceDataImages.scan_data,
                patient_images_xray: braceDataImages.patient_images_xray,
                patient_images_back: braceDataImages.patient_images_back,
                patient_images_front: braceDataImages.patient_images_front,
                patient_images_left: braceDataImages.patient_images_left,
                patient_images_right: braceDataImages.patient_images_right,
                patient_images_forwardbending: braceDataImages.patient_images_forwardbending,
                visitxray_pic_xray: braceDataImages.visitxray_pic_xray,
                visitxray_pic_brace_back: braceDataImages.visitxray_pic_brace_back,
                visitxray_pic_brace_front: braceDataImages.visitxray_pic_brace_front,
            });

            // Assuming the fetched data has the image data in Base64 format
            const newImagesData = {
                scan_data: braceDataImages.scan_data ? base64ToBlob(braceDataImages.scan_data, 'image/jpeg') : null,
                patient_images_xray: braceDataImages.patient_images_xray ? base64ToBlob(braceDataImages.patient_images_xray, 'image/jpeg') : null,
                patient_images_back: braceDataImages.patient_images_back ? base64ToBlob(braceDataImages.patient_images_back, 'image/jpeg') : null,
                patient_images_front: braceDataImages.patient_images_front ? base64ToBlob(braceDataImages.patient_images_front, 'image/jpeg') : null,
                patient_images_left: braceDataImages.patient_images_left ? base64ToBlob(braceDataImages.patient_images_left, 'image/jpeg') : null,
                patient_images_right: braceDataImages.patient_images_right ? base64ToBlob(braceDataImages.patient_images_right, 'image/jpeg') : null,
                patient_images_forwardbending: braceDataImages.patient_images_forwardbending ? base64ToBlob(braceDataImages.patient_images_forwardbending, 'image/jpeg') : null,
                visitxray_pic_xray: braceDataImages.visitxray_pic_xray ? base64ToBlob(braceDataImages.visitxray_pic_xray, 'image/jpeg') : null,
                visitxray_pic_brace_back: braceDataImages.visitxray_pic_brace_back ? base64ToBlob(braceDataImages.visitxray_pic_brace_back, 'image/jpeg') : null,
                visitxray_pic_brace_front: braceDataImages.visitxray_pic_brace_front ? base64ToBlob(braceDataImages.visitxray_pic_brace_front, 'image/jpeg') : null,
            };

            // Set images and newImages state directly
            setImages({
                scan_data: braceDataImages.scan_data,
                patient_images_xray: braceDataImages.patient_images_xray,
                patient_images_back: braceDataImages.patient_images_back,
                patient_images_front: braceDataImages.patient_images_front,
                patient_images_left: braceDataImages.patient_images_left,
                patient_images_right: braceDataImages.patient_images_right,
                patient_images_forwardbending: braceDataImages.patient_images_forwardbending,
                visitxray_pic_xray: braceDataImages.visitxray_pic_xray,
                visitxray_pic_brace_back: braceDataImages.visitxray_pic_brace_back,
                visitxray_pic_brace_front: braceDataImages.visitxray_pic_brace_front,
            });

            setNewImages(newImagesData);
        };

        fetchData();
        fetchBraceDataImages();
    }, [id]);

    if (loading && !braceData) return <div className="py-32 text-center"><Loading /><p className='py-12'>Loading Patient's Brace Data...</p></div>;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!braceData) return <p className="py-16 text-center">Brace data not found. Please try again.</p>;

    return (
        <form onSubmit={handleSubmit}>
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t("edit")}{t("brace_data")}</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t("cancel")}
                    </Button>
                    <Button type="submit" className="ml-3" disabled={(!braceData.date || !braceData.patient_id || !braceData.brace_priority)}>{t("save")}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />

            {/* Patient ID Section */}
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-6 md:grid-cols-3">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("patient_personal_information")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t("patient_personal_information_description")}
                    </p>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className="sm:col-span-full">
                        <Subheading>{t("patient")}</Subheading>
                        <Text fontSize='text-xl' className='mt-2'>{braceData.fullname}</Text>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>{t("brace")}</Subheading>
                            </div>
                            <RadioGroup
                                name="brace_priority"
                                value={braceData.brace_priority || ''}
                                onChange={handleBracePriorityChange}
                            >
                                <RadioField>
                                    <Radio value="Primary Brace"></Radio><Label>{t("primary_brace")}</Label>
                                </RadioField>
                                <RadioField>
                                    <Radio value="Secondary Brace"></Radio><Label>{t("secondary_brace")}</Label>
                                </RadioField>
                            </RadioGroup>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>{t("date")}</Subheading>
                            </div>
                            <Input
                                type="date"
                                aria-label="date"
                                name="date"
                                value={braceData.date || ''}
                                onChange={handleInputChange}
                            />
                        </section>
                    </div>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />

            {/* Patient Measurement Info */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("patient_information")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("patient_information_description")}
                        </p>
                    </div>
                    <div className="sm:col-span-full">
                        <section className="grid grid-cols-2 gap-8 md:grid-cols-7">
                            <div className="grid gap-4 col-span-3">
                                <img
                                    src={'/images/BraceDataRefer1.png'}
                                    alt="Patient Measurement Info"
                                    className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                />
                            </div>

                            <div className="py-2">
                                <div className="grid grid-rows-7 grid-flow-col gap-1.5">
                                    <div></div>
                                    {[t('armpit'), t('xiphoid'), t('waist'), t('spine_height'), t('trocanter'), t('spine_width')].map((label, index) => (
                                        <div key={index} className="text-md py-1.5 my-1">{label}</div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-7 grid-flow-col text-center gap-4">
                                    <div>{t("size")}</div>
                                    {['armpit_size', 'xiphoid_size', 'waist_size', 'spineheight_size', 'trocanter_size', 'spine_width'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`${name.replace(/size$/, ' Size').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                onChange={handleInputChange}
                                                value={(braceData as any)?.[name]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-7 grid-flow-col text-center gap-4">
                                    <div>{t("ll")}</div>
                                    {['armpit_ll', 'xiphoid_ll', 'waist_ll', 'spineheight_ll', 'trocanter_ll'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`${name.replace(/ll$/, ' L-L').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                onChange={handleInputChange}
                                                value={(braceData as any)?.[name]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-7 grid-flow-col text-center gap-4">
                                    <div>{t("ap")}</div>
                                    <Input
                                        type="text"
                                        aria-label={'armpit_ap'}
                                        name={'armpit_ap'}
                                        placeholder={'Armpit A-P'}
                                        onChange={handleInputChange}
                                        value={braceData.armpit_ap!}
                                    />
                                    <div className="grid grid-cols-2 grid-flow-col text-center gap-4">
                                        <div>
                                            <Input
                                                type="text"
                                                aria-label={'xiphoid_ap_left'}
                                                name={'xiphoid_ap_left'}
                                                placeholder={'A-P Left'}
                                                onChange={handleInputChange}
                                                value={braceData.xiphoid_ap_left!}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                aria-label={'xiphoid_ap_right'}
                                                name={'xiphoid_ap_right'}
                                                placeholder={'A-P Right'}
                                                onChange={handleInputChange}
                                                value={braceData.xiphoid_ap_right!}
                                            />
                                        </div>
                                    </div>
                                    {['waist_ap', 'spineheight_ap', 'trocanter_ap'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`${name.replace(/ap$/, ' A-P').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                onChange={handleInputChange}
                                                value={(braceData as any)?.[name]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section className="grid grid-cols-2 gap-8 md:grid-cols-7">
                            <div className="grid gap-4 col-span-3">
                                <img
                                    src={'/images/BraceDataRefer2.png'}
                                    alt="Patient Measurement Info"
                                    className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                />
                            </div>
                            <div className="py-2">
                                <div className="grid grid-rows-6 grid-flow-col gap-1.5">
                                    <div></div>
                                    {[t('parameter') + " 1", t('parameter') + " 2", t('parameter') + " 3", t('parameter') + " 4", t('parameter') + " 5"].map((label, index) => (
                                        <div key={index} className="text-md py-1.5 my-1">{label}</div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-6 grid-flow-col text-center gap-4">
                                    <div></div>
                                    {['add2_parameter_1', 'add2_parameter_2', 'add2_parameter_3', 'add2_parameter_4', 'add2_parameter_5'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`Parameter ${index + 1}`}
                                                onChange={handleInputChange}
                                                value={(braceData as any)?.[name]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <Divider className="mt-5 mb-8" />

            {/* Patient Images Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("patient_images")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("patient_images_description")}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        {['patient_images_xray', 'patient_images_back', 'patient_images_front', 'patient_images_left', 'patient_images_right', 'patient_images_forwardbending'].map((name, index) => (
                            <div key={index} className="sm:col-span-full">
                                <section>
                                    <Subheading>{`${name.replace(/patient_images_/g, '').replace(/\b\w/g, char => char.toUpperCase())}`}</Subheading>
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6 col-span-2">
                                            <div className="text-center self-center">
                                                <div className="mt-4 text-sm leading-6 text-gray-600">
                                                    <label
                                                        htmlFor={`file-upload-${name}`}
                                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500 "
                                                    >
                                                        <span>{t("upload_an_image")}</span>
                                                        <input
                                                            id={`file-upload-${name}`}
                                                            name={name}
                                                            type="file"
                                                            className="sr-only"
                                                            accept="image/*"
                                                            onChange={(e) => handleFileChange(e, `${name}`)}
                                                        />
                                                    </label>

                                                </div>
                                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG up to 8MB</p>
                                            </div>
                                        </div>
                                        <div>
                                            {images?.[name] ? (
                                                <div>
                                                    {
                                                        images?.[name] && (
                                                            <div>
                                                                <img
                                                                    className="w-2/3 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                                                    src={images?.[name]!}
                                                                    alt={`${name.replace(/patient_images_/g, '').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (<Text className='py-5 self-center items-center'>No images available</Text>)}
                                        </div>
                                    </div>
                                    {fileNames[`${name}`] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames[`${name}`]}</strong>
                                        </p>
                                    )}
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Divider className="mt-5 mb-8" />

            {/* Additional Brace Info Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("additional_brace_info")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("additional_brace_info_description")}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t("additional_brace_info")}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="additional_brace_info"
                                        name="additional_brace_info"
                                        value={braceData.additional_brace_info || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <Divider className="mt-5 mb-8" />

            {/* Scan Data Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("patient_scan_data")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("patient_scan_data_description")}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className=''>
                                <div className="col-span-full">
                                    <Subheading>{t('upload')}{t('patient_scan_data')}</Subheading>
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6 col-span-2">
                                            <div className="text-center self-center">
                                                <div className="mt-4 text-sm leading-6 text-gray-600">
                                                    <label
                                                        htmlFor="file-upload-scan_data"
                                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500"
                                                    >
                                                        <span>{t("upload_a_file")}</span>
                                                        <input
                                                            id="file-upload-scan_data"
                                                            name="scan_data"
                                                            type="file"
                                                            accept=".stl, .zip, .rar, .jpg"
                                                            onChange={(e) => handleFileChange(e, 'scan_data')}
                                                            className="sr-only"
                                                        />
                                                    </label>
                                                </div>
                                                <p className="text-xs leading-5 text-gray-600">STL, ZIP, RAR up to 10MB</p>
                                            </div>
                                        </div>
                                        <div>
                                            {images?.scan_data ? (
                                                <div>
                                                    {
                                                        images.patient_images_xray && (
                                                            <div>
                                                                <img
                                                                    className="w-2/3 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                                                    src={images?.scan_data!}
                                                                    alt="Patient Scan Data"
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (<Text className='py-5 items-center'>No images available</Text>)}
                                        </div>
                                    </div>
                                    {fileNames['scan_data'] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames.scan_data}</strong>
                                        </p>
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            {/* Visit with XRay Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("visit_with_xray")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("visit_with_xray_description")}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t("cobb_angle_thoracic")}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="visitxray_cobb_th"
                                        name="visitxray_cobb_th"
                                        value={braceData.visitxray_cobb_th || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t("cobb_angle_lumbar")}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="visitxray_cobb_i"
                                        name="visitxray_cobb_i"
                                        value={braceData.visitxray_cobb_i || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </section>
                        </div>
                        {['visitxray_pic_xray', 'visitxray_pic_brace_back', 'visitxray_pic_brace_front'].map((name, index) => (
                            <div key={index} className="sm:col-span-full">
                                <section>
                                    <Subheading>{`${name.replace(/visitxray_pic_/g, 'Picture with Brace: ').replace(/\b\w/g, char => char.toUpperCase())}`}</Subheading>
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6 col-span-2">
                                            <div className="text-center self-center">
                                                <div className="mt-4 text-sm leading-6 text-gray-600">
                                                    <label
                                                        htmlFor={`file-upload-${name}`}
                                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500 "
                                                    >
                                                        <span>{t("upload_an_image")}</span>
                                                        <input
                                                            id={`file-upload-${name}`}
                                                            name={name}
                                                            type="file"
                                                            className="sr-only"
                                                            accept="image/*"
                                                            onChange={(e) => handleFileChange(e, `${name}`)}
                                                        />
                                                    </label>

                                                </div>
                                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG up to 8MB</p>
                                            </div>
                                        </div>
                                        <div>
                                            {images?.[name] ? (
                                                <div>
                                                    {
                                                        images.patient_images_xray && (
                                                            <div>
                                                                <img
                                                                    className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                                                    src={images?.[name]!}
                                                                    alt={`${name.replace(/patient_images_/g, '').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (<Text className='py-5 items-center'>No images available</Text>)}
                                        </div>
                                    </div>
                                    {fileNames[`${name}`] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames[`${name}`]}</strong>
                                        </p>
                                    )}
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    {t("cancel")}
                </Button>
                <Button type="submit" disabled={(!braceData.date || !braceData.patient_id || !braceData.brace_priority)}>{t("save")}</Button>
            </div>
        </form>
    );
};

export default UpdateBraceDataPage;