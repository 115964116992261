// frontend/src/context/UserContext.tsx
import React, { createContext, useState, useEffect } from 'react';
import { User } from '../models/User';
import { UserService } from '../services/userService';

interface UserContextType {
    currentUser: User | null;
    setCurrentUser: (user: User | null) => void;
    logout: (navigate: (path: string) => void) => void;
}

export const UserContext = createContext<UserContextType>({
    currentUser: null,
    setCurrentUser: () => { },
    logout: () => { },
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('gratefulmstoken');
        if (token) {
            UserService.getProfile()
                .then((user) => {
                    setCurrentUser(user);
                })
                .catch((error) => {
                    console.error('Failed to fetch user profile:', error);
                    // Token is invalid or profile fetching failed, so log out
                    logout(() => window.location.assign('/')); // Use window.location as fallback
                });
        }
    }, []);

    const logout = (navigate: (path: string) => void) => {
        UserService.logout();
        setCurrentUser(null);
        navigate('/'); // Redirect to login page
    };

    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser, logout }}>
            {children}
        </UserContext.Provider>
    );
};
