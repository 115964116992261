// services/courseParticipantService.ts

import axios from 'axios';
import { CourseParticipant } from '../models/GhCourseParticipants';
import { GetImagesResponse } from '../models/ImagesModel';

const API_URL = process.env.REACT_APP_SERVER_URL + '/ghcourseparticipants';

export const courseParticipantService = {
    // Fetch all course participants
    getAllCourseParticipants: async (): Promise<CourseParticipant[]> => {
        try {
            const response = await axios.get(`${API_URL}/getAllParticipants`);
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching course participants: ${error}`);
        }
    },

    // Fetch a course participant by ID
    getCourseParticipantById: async (id: number): Promise<CourseParticipant> => {
        try {
            const response = await axios.get(`${API_URL}/getParticipantById/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching course participant by ID: ${error}`);
        }
    },

    getCourseParticipantImagesById: async (id: number): Promise<GetImagesResponse> => {
        try {
            // Make GET request to retrieve patient images
            const response = await axios.get(`${API_URL}/getParticipantImagesById/${id}`);
            return response.data; // Should match the GetImagesResponse type
        } catch (error) {
            console.error('Error retrieving participant images:', error);
            throw error;
        }
    },

    // Create a new course participant
    createCourseParticipant: async (courseParticipantData: Omit<CourseParticipant, 'id' | 'created_time' | 'updated_time'>, images: any) => {
        try {
            const formData = new FormData();

            formData.append('course_type_id', courseParticipantData.course_type_id!.toString());
            formData.append('fullname', courseParticipantData.fullname || '');
            formData.append('mobile_no', courseParticipantData.mobile_no || '');
            formData.append('email', courseParticipantData.email || '');
            formData.append('complete_date', courseParticipantData.complete_date || '');

            if (images.certificate) formData.append('certificate', images.certificate);
            if (images.certificate_2) formData.append('certificate_2', images.certificate_2);

            // Send the form data using axios
            const response = await axios.post(`${API_URL}/add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            throw new Error(`Error adding course participant: ${error}`);
        }
    },

    // Update an existing course participant
    updateCourseParticipant: async (id: number, courseParticipantData: Omit<CourseParticipant, 'id' | 'created_time' | 'updated_time'>, updatedImages: any) => {
        try {
            const formData = new FormData();

            formData.append('course_type_id', courseParticipantData.course_type_id!.toString());
            formData.append('fullname', courseParticipantData.fullname || '');
            formData.append('mobile_no', courseParticipantData.mobile_no || '');
            formData.append('email', courseParticipantData.email || '');
            formData.append('complete_date', courseParticipantData.complete_date || '');

            if (updatedImages.certificate) formData.append('certificate', updatedImages.certificate);
            if (updatedImages.certificate_2) formData.append('certificate_2', updatedImages.certificate_2);

            // Send the form data using axios
            const response = await axios.put(`${API_URL}/update/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            throw new Error(`Error updating course participant: ${error}`);
        }
    },

    // Delete a course participant
    deleteCourseParticipant: async (id: number): Promise<void> => {
        try {
            await axios.delete(`${API_URL}/delete/${id}`);
        } catch (error) {
            throw new Error(`Error deleting course participant: ${error}`);
        }
    }
};
