import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../components/Heading';
import { Badge } from '../../../components/Badge';
import { Button } from '../../../components/Button';
import { Divider } from '../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../components/Description-List';
import { Avatar } from '../../../components/Avatar';
import { UserService } from '../../../services/userService'; // Import the UserService
import Loading from '../../../components/Loading';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../components/Dialog';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import showSuccessToast from '../../../components/Toasts/ShowSuccessToast';

const GHUserIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [user, setUser] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false); // State for dialog open status
    const [isResetting, setIsResetting] = useState(false); // State for reset password loading
    const [resetMessage, setResetMessage] = useState<string | null>(null); // Message for reset success/error
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${t('view')} ${t('users')}`
    }, []);

    useEffect(() => {
        if (!id) return; // If id is not available, return early

        // Fetch user details when the component mounts
        const fetchUser = async () => {
            try {
                setLoading(true);
                const userData = await UserService.getUserById(id);
                setUser(userData);
            } catch (err) {
                console.error('Failed to fetch user details:', err);
                setError('Failed to load user details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    // Function to handle resetting the password
    const handleResetPassword = async () => {
        if (!user) return;
        if (!user.email) {
            showErrorToast('Email is required for resetting password');
            return;
        }
        setIsResetting(true);
        setResetMessage(null);

        try {
            const result = await UserService.resetPasswordByRandom(user.username);
            setResetMessage(result.message);
            if (result.dbsuccess) {
                showSuccessToast(result.message);
            } else {
                showErrorToast(result.message);
            }
        } catch (err) {
            console.error('Failed to reset password:', err);
            setResetMessage('Failed to reset password. Please try again.');
            showErrorToast('Failed to reset password. Please try again.');
        } finally {
            setIsResetting(false);
            setIsOpen(false); // Close the dialog after action
        }
    };

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!user) return <p className='py-16 text-center'>User not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gh/users" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    {t('user')}
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={user.fullname[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{user.fullname}</Heading>
                    <Badge color="lime">{user.rolename.toUpperCase()}</Badge>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>{t('created_on')}</span><span>{dateFormat(user.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button outline onClick={() => setIsOpen(true)}>{t('reset_password')}</Button>
                        <Button onClick={() => navigate(`/gh/users/edit/${user.id}`)}>{t('edit')}</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>{t('profile')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('fullname')}</DescriptionTerm>
                    <DescriptionDetails>{user.fullname}</DescriptionDetails>
                    <DescriptionTerm>{t('email')}</DescriptionTerm>
                    <DescriptionDetails>{user.email || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('mobile_no')}</DescriptionTerm>
                    <DescriptionDetails>{user.mobile_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('street_address')}</DescriptionTerm>
                    <DescriptionDetails>{user.street_address || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('remarks')}</DescriptionTerm>
                    <DescriptionDetails>{user.remarks || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
            <div className="mt-12">
                <Subheading>{t('account_information')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('username')}</DescriptionTerm>
                    <DescriptionDetails>{user.username}</DescriptionDetails>
                    <DescriptionTerm>{t('referrer')}</DescriptionTerm>
                    <DescriptionDetails>{user.referrer_fullname}</DescriptionDetails>
                    <DescriptionTerm>{t('role')}</DescriptionTerm>
                    <DescriptionDetails className='font-medium'>{user.rolename.toUpperCase()}</DescriptionDetails>
                    <DescriptionTerm>{t('agent_no')}</DescriptionTerm>
                    <DescriptionDetails className='font-medium'>{user.agent_no || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>

            {/* Reset Password Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>{t('reset_password')}</DialogTitle>
                <DialogDescription>
                    Are you sure you want to reset this user’s password? A new password will be sent to the user's email.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isResetting}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleResetPassword} disabled={isResetting}>
                        {isResetting ? 'Resetting...' : t('reset')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GHUserIdPage;
