import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../context/userContext';
import { Heading, Subheading } from '../../../components/Heading';
import { Select } from '../../../components/Select';
import { Badge } from '../../../components/Badge';
import { useTranslation } from 'react-i18next';
import useTchPillowPatientService from '../../../hooks/useTchPillowPatient';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { Text } from '../../../components/Text';

export function Stat({ title, value }: { title: string; value: string }) {
    return (
        <div className='bg-zinc-100 p-6 rounded-md ring-1 ring-gray-100'>
            <div className="text-lg/6 font-medium sm:text-sm/6">{title}</div>
            <div className="mt-3 text-3xl/8 font-semibold sm:text-2xl/8">{value}</div>
        </div>
    );
}

const TchDashboard: React.FC = () => {
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();
    const { pillowPatients } = useTchPillowPatientService();

    // Get current hour to set greeting message
    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) return t('good_morning');
        if (currentHour < 18) return t('good_afternoon');
        return t('good_evening');
    };

    useEffect(() => {
        document.title = `${t('tch_dashboard')}`
    }, []);

    return (
        <>
            <Heading>{getGreeting()}, {currentUser ? currentUser.fullname : "Guest"}</Heading>
            <div className="mt-8 flex items-end justify-between">
                <Subheading>{t('dashboard')}</Subheading>
            </div>
            <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-3">
                <Stat title={t('pillow_buyers')} value={`${pillowPatients.length.toString()} ${t('pillow_buyers')}`} />
            </div>

            <div>
                <Subheading className="mt-14">{t('pillow_buyers')}</Subheading>
                {(pillowPatients.length > 0) ? <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>{t('name')}</TableHeader>
                            <TableHeader>{t('gender')}</TableHeader>
                            <TableHeader className="text-right">{t('created_on')}</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pillowPatients.map((patient) => (
                            <TableRow key={patient.id} title={`${patient.fullname}`}>
                                <TableCell className="text-zinc-500">{patient.fullname}</TableCell>
                                <TableCell>{patient.gender}</TableCell>
                                <TableCell className="text-right">{dateFormat(patient.created_time, "mmmm dd, yyyy")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table> : <Text className='py-12'>No patients found.</Text>}
            </div>
        </>
    );
};

export default TchDashboard;
