import { useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";
import { Outlet, useNavigate } from "react-router-dom";

// Define the props type
interface PageAccessProps {
    requiredRole?: string;
    requiredRole2?: string;
    requiredRole3?: string;
}

// Specify types in the component function
const PageAccess: React.FC<PageAccessProps> = ({ requiredRole, requiredRole2, requiredRole3 }) => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser?.role!.toString() !== requiredRole && currentUser?.role!.toString() !== requiredRole2 && currentUser?.role!.toString() !== requiredRole3) {
            navigate('/accessdenied');
        }
    }, [currentUser, navigate, requiredRole, requiredRole2, requiredRole3]);

    return (
        <div>
            <Outlet />
        </div>
    );
};

export default PageAccess;
