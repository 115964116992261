import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import Select from 'react-select';
import { PhotoIcon } from '@heroicons/react/16/solid';
import tchPillowPatientImagesService from '../../../../../services/tchpillowpatientimagesService';
import useTchPillowPatientService from '../../../../../hooks/useTchPillowPatient';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface AddTchPillowPatient {
    patient_id: string;
    files: File[];
}

const AddTchPillowPatientImagePage: React.FC = () => {
    const navigate = useNavigate();
    const { pillowPatients, loading } = useTchPillowPatientService();
    const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [imagesURLs, setImageURLs] = useState<string[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('add')} ${t('new')} ${t('pillow_buyers')} ${t('patient_images')}`
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            setFiles(Array.from(selectedFiles));

            // Generate image preview URLs
            const urls = Array.from(selectedFiles).map(file => URL.createObjectURL(file));
            setImageURLs(urls);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!selectedPatient) {
            showErrorToast('Please select a patient.');
            return;
        }

        if (files.length === 0) {
            showErrorToast('Please upload at least one image.');
            return;
        }


        try {
            const toastId = showLoadingToast('Uploading images...');
            const response = await tchPillowPatientImagesService.uploadPatientImages(selectedPatient, files);
            if (response.Success) {
                toast.dismiss(toastId);
                navigate('/tch/products/pillows/patients');
                showSuccessToast(`Pillow patient images added successfully`);
            } else if (response.Error) {
                showErrorToast(response.Error);
            }
        } catch (error) {
            showErrorToast('An error occurred while uploading images.');
            console.error('Upload error:', error);
        }
    };

    const selectOptions = pillowPatients.map((patient: any) => ({
        value: patient.id,
        label: patient.fullname
    }));

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('add')} {t('new')} {t('patient_images')}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!selectedPatient || files.length === 0}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_information')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('patient_information_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('patient')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        id="patient_id"
                                        name="patient_id"
                                        options={selectOptions}
                                        className="block w-full rounded-md border-0 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                        placeholder="Select Patient"
                                        onChange={(option) => setSelectedPatient(option?.value || null)}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className=''>
                                <div className="col-span-full">
                                    <Subheading>{t('upload')} {t('patient_images')}</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                            <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                                            <div className="mt-4 text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500"
                                                >
                                                    <span>{t('upload_an_image')}</span>
                                                    <input
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/gif"
                                                        multiple
                                                        onChange={handleChange}
                                                        className="sr-only"
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* Image preview */}
                        <div className="sm:col-span-full">
                            <Subheading>{t('preview')}</Subheading>
                            <div className="mt-4 grid grid-cols-2 gap-4">
                                {(imagesURLs.length > 0) ? imagesURLs.map((url, index) => (
                                    <>
                                        <img
                                            key={index}
                                            src={url}
                                            alt={`Preview ${index + 1}`}
                                            className="w-full h-full object-cover rounded-md"
                                        />
                                        <label className="block text-sm font-medium leading-6 text-gray-900">{url}</label></>
                                )) : <label className="block text-sm font-medium leading-6 text-gray-900">No patient image selected</label>}
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className="flex justify-end gap-4 my-6">
                        <Button type="reset" plain onClick={() => navigate(-1)}>
                            {t('cancel')}
                        </Button>
                        <Button type="submit" disabled={!selectedPatient || files.length === 0}>{t('save')}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddTchPillowPatientImagePage;
