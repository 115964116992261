// frontend/src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './app/Login/Page';
import Dashboard from './app/GH/Dashboard/Page';
import PrivateRoute from './app/PrivateRoute';
import { UserProvider } from './context/userContext';
import { Toaster } from 'react-hot-toast';
import { SuperAdminProvider } from './provider/superadminProvider';
import { GHLayout } from './app/Layout/GHLayout';
import AccessDenied from './app/404/AccessDenied/Page';
import TchPillowPatientPage from './app/TCH/Pillows/Patients/Page';
import AddTchPillowPatientPage from './app/TCH/Pillows/Patients/add/Page';
import GHDashboard from './app/GH/Dashboard/Page';
import GHUsersPage from './app/Users/Page';
import GHUserIdPage from './app/Users/[id]/Page';
import GHEditUserPage from './app/Users/edit/Page';
import GHAddUserPage from './app/Users/add/Page';
import EditTchPillowPatientPage from './app/TCH/Pillows/Patients/edit/Page';
import TchPillowPatientIdPage from './app/TCH/Pillows/Patients/[id]/Page';
import TchDashboard from './app/TCH/Dashboard/Page';
import AddTchPillowPatientImagePage from './app/TCH/Pillows/PatientsImages/add/Page';
import GHBracePatientPage from './app/GH/Brace/Patients/Page';
import GHAddBracePatientPage from './app/GH/Brace/Patients/add/Page';
import GHEditBracePatientPage from './app/GH/Brace/Patients/edit/Page';
import GHAddBraceDataPage from './app/GH/Brace/PatientsBraceData/add/Page';
import GHAddBraceDataPage11 from './app/GH/Brace/PatientsBraceData/add/Page_Id';
import GHBracePatientIdPage from './app/GH/Brace/Patients/[id]/Page';
import { TCHLayout } from './app/Layout/TCHLayout';
import UpdateBraceDataPage from './app/GH/Brace/PatientsBraceData/edit/Page';
import ViewBraceDataPage from './app/GH/Brace/PatientsBraceData/[id]/Page';
import GHCourseTypesPage from './app/GH/Course/CourseTypes/Page';
import GHAddCourseTypePage from './app/GH/Course/CourseTypes/add/Page';
import GHEditCourseTypePage from './app/GH/Course/CourseTypes/edit/Page';
import GHViewCourseTypeIdPage from './app/GH/Course/CourseTypes/[id]/Page';
import GHCourseParticipantsPage from './app/GH/Course/Participants/Page';
import GHAddCourseParticipantPage from './app/GH/Course/Participants/add/Page';
import GHEditCourseParticipantPage from './app/GH/Course/Participants/edit/Page';
import GHViewCourseParticipantIdPage from './app/GH/Course/Participants/[id]/Page';
import NotFoundPage from './app/404/404NotFound/Page';
import GHAddBracePatientImagesPage from './app/GH/Brace/PatientsImages/add/Page';
import EditMyAccountPage from './app/MyAccount/edit/Page';
import ViewMyAccountPage from './app/MyAccount/[id]/Page';
import PageAccess from './app/PageAccess';

const App: React.FC = () => {
  return (
    <UserProvider>
      <Toaster
        position="bottom-right"
      />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/accessdenied" element={<AccessDenied />} />

          <Route element={<PrivateRoute />}>

            <Route element={<PageAccess requiredRole='1' requiredRole2='2' />}>
              <Route element={<GHLayout />}>
                <Route path="gh/dashboard" element={<GHDashboard />} />

                {/* Users Pages */}
                <Route path="gh/users" element={<GHUsersPage />} />
                <Route path="gh/users/view/:id" element={<GHUserIdPage />} />
                <Route path="gh/users/edit/:id" element={<GHEditUserPage />} />
                <Route path="gh/users/add" element={<GHAddUserPage />} />

                {/* Brace Patients Pages */}
                <Route path="gh/braces/patients" element={<GHBracePatientPage />} />
                <Route path="gh/braces/patients/add" element={<GHAddBracePatientPage />} />
                <Route path="gh/braces/patients/edit/:id" element={<GHEditBracePatientPage />} />
                <Route path="gh/braces/patients/view/:id" element={<GHBracePatientIdPage />} />
                <Route path="gh/braces/patients/images/add" element={<GHAddBracePatientImagesPage />} />
                <Route path="gh/braces/patients/images/add/:id" element={<GHAddBracePatientImagesPage />} />

                {/* Brace Data Pages */}
                <Route path="gh/braces/data/add/:id" element={<GHAddBraceDataPage11 />} />
                <Route path="gh/braces/data/edit/:id" element={<UpdateBraceDataPage />} />
                <Route path="gh/braces/data/view/:id" element={<ViewBraceDataPage />} />

                {/* Course Types Pages */}
                <Route path="gh/courses/types" element={<GHCourseTypesPage />} />
                <Route path="gh/courses/types/add" element={<GHAddCourseTypePage />} />
                <Route path="gh/courses/types/edit/:id" element={<GHEditCourseTypePage />} />
                <Route path="gh/courses/types/view/:id" element={<GHViewCourseTypeIdPage />} />

                {/* Course Participants Pages */}
                <Route path="gh/courses/participants" element={<GHCourseParticipantsPage />} />
                <Route path="gh/courses/participants/add" element={<GHAddCourseParticipantPage />} />
                <Route path="gh/courses/participants/edit/:id" element={<GHEditCourseParticipantPage />} />
                <Route path="gh/courses/participants/view/:id" element={<GHViewCourseParticipantIdPage />} />

                {/* My Account Pages */}
                <Route path="gh/myaccount/edit" element={<EditMyAccountPage />} />
                <Route path="gh/myaccount/view" element={<ViewMyAccountPage />} />
              </Route>
            </Route>

            <Route element={<TCHLayout />}>
              {/* TCH Pillow Pages */}
              <Route path="tch/products/pillows/patients" element={<TchPillowPatientPage />} />
              <Route path="tch/products/pillows/patients/add" element={<AddTchPillowPatientPage />} />
              <Route path="tch/products/pillows/patients/edit/:id" element={<EditTchPillowPatientPage />} />
              <Route path="tch/products/pillows/patients/view/:id" element={<TchPillowPatientIdPage />} />
              <Route path="tch/products/pillows/patients/images/add" element={<AddTchPillowPatientImagePage />} />

              <Route path="tch/dashboard" element={<TchDashboard />} />

              {/* My Account Pages */}
              <Route path="tch/myaccount/edit" element={<EditMyAccountPage />} />
              <Route path="tch/myaccount/view" element={<ViewMyAccountPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </UserProvider>

  );
};

export default App;
