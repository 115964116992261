import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from '../../../services/userService';
import { User } from '../../../models/User';
import { Heading, Subheading } from '../../../components/Heading';
import { Divider } from '../../../components/Divider';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Select } from '../../../components/Select';
import { Textarea } from '../../../components/Textarea';
import { Button } from '../../../components/Button';
import showLoadingToast from '../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../components/Toasts/ShowSuccessToast';
import useUserService from '../../../hooks/useUserService';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../hooks/useRoles';
import { UserContext } from '../../../context/userContext';
import { useTranslation } from 'react-i18next';

const EditMyAccountPage = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [user, setUser] = useState<User | null>(null);
    const { getUserById, loading, error } = useUserService();
    const { roles } = useRoles();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('edit')} ${t('my_account')}`
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const fetchedUser = await getUserById(currentUser?.id!.toString()!);
                setUser(fetchedUser);
            } catch (err) {
                showErrorToast('Failed to fetch my account details.');
            }
        };

        fetchUserDetails();
    }, [currentUser]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => prev ? { ...prev, [name]: name === 'role' ? BigInt(value) : value } : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user) return;

        try {
            const res = await UserService.updateUser(user.id.toString(), user);
            if (res.dbsuccess) {
                showLoadingToast(`Updating ${user.fullname} ...`, 100);
                setTimeout(() => {
                    if (currentUser?.role!.toString() !== "1" && currentUser?.role!.toString() !== "2") {
                        navigate('/tch/myaccount/view');
                    } else {
                        navigate('/gh/myaccount/view');
                    }
                    showSuccessToast(`${user.fullname} updated successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error updating user`);
            }
        } catch (err) {
            showErrorToast('Failed to update user.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!user) return <p>User not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('edit')}{t('user')}</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className="ml-3">{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('profile')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('profile_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('fullname')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={user.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('email')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={user.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('mobile_no')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={user.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('street_address')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Street Address"
                                        name="street_address"
                                        value={user.street_address?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('remarks')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={user.remarks?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className='space-y-12'>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('account_information')}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                {t('account_information_description')}
                            </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('username')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="Username"
                                            name="username"
                                            value={user.username}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('role')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            aria-label="Role"
                                            name="role"
                                            value={user.role!.toString()}
                                            onChange={handleChange}
                                            disabled
                                        >
                                            {roles.map(role => (
                                                <option key={role.id.toString()} value={role.id.toString()}>
                                                    {role.rolename}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    {t('cancel')}
                </Button>
                <Button type="submit">{t('save')}</Button>
            </div>
        </form>
    );
};

export default EditMyAccountPage;
