// hooks/useCourseParticipants.ts

import { useState, useEffect } from 'react';
import { CourseParticipant } from '../models/GhCourseParticipants';
import { courseParticipantService } from '../services/ghcourseparticipantsService';
import { GetImagesResponse } from '../models/ImagesModel';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export function useCourseParticipants() {
    const [participants, setParticipants] = useState<CourseParticipant[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [totalCourseParticipants, setTotalCourseParticipants] = useState<number>(0);

    const fetchParticipants = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await courseParticipantService.getAllCourseParticipants();
            setParticipants(data);
            setTotalCourseParticipants(data.length);
        } catch (err: any) {
            setError(err.message);
            showErrorToast(`Failed fetching all course participants ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchParticipantById = async (id: number) => {
        setLoading(true);
        setError(null);

        try {
            const participant = await courseParticipantService.getCourseParticipantById(id);
            return participant;
        } catch (err: any) {
            setError(err.message);
            showErrorToast(`Failed fetching all course participants ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const fetchParticipantImagesById = async (id: number) => {
        setLoading(true);
        setError(null);

        try {
            const result: GetImagesResponse = await courseParticipantService.getCourseParticipantImagesById(id);
            return result; // Ensure you return the result
        } catch (err) {
            showErrorToast(`Failed fetching course participants images ${err}`);
            setError(`Error fetching participant Images with ID: ${id} ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createParticipant = async (data: Omit<CourseParticipant, 'id' | 'created_time' | 'updated_time'>, images: any) => {
        setLoading(true);
        setError(null);

        try {
            setLoading(true);
            const newParticipantRes = await courseParticipantService.createCourseParticipant(data, images);
            setParticipants((prev) => [...prev, newParticipantRes]);
            return newParticipantRes;
        } catch (err: any) {
            setError(err.message);
            showErrorToast(`Failed creating course participants ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const updateParticipant = async (id: number, data: Omit<CourseParticipant, 'id' | 'created_time' | 'updated_time'>, updatedImages: any) => {
        setLoading(true);
        setError(null);

        try {
            setLoading(true);
            const updatedParticipantRes = await courseParticipantService.updateCourseParticipant(id, data, updatedImages);
            setParticipants((prev) =>
                prev.map((participant) =>
                    participant.id === id ? updatedParticipantRes : participant
                )
            );
            return updatedParticipantRes;
        } catch (err: any) {
            setError(err.message);
            showErrorToast(`Failed updating course participants ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const deleteParticipant = async (id: number) => {
        try {
            setLoading(true);
            await courseParticipantService.deleteCourseParticipant(id);
            setParticipants((prev) =>
                prev.filter((participant) => participant.id !== id)
            );
        } catch (err: any) {
            setError(err.message);
            showErrorToast(`Failed deleting course participants ${err}`);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchParticipants();
    }, []);

    return {
        participants,
        loading,
        error,
        fetchParticipants,
        fetchParticipantById,
        fetchParticipantImagesById,
        createParticipant,
        updateParticipant,
        deleteParticipant,
        totalCourseParticipants,
    };
}
