// frontend/src/pages/LoginPage.tsx
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../services/userService';
import { UserContext } from '../../context/userContext';
import { CheckIcon, EllipsisVerticalIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/16/solid';
import { Avatar } from '../../components/Avatar';
import Loading from '../../components/Loading';
import { Text } from '../../components/Text';
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '../../components/Dropdown';
import { useTranslation } from 'react-i18next';
import showLoadingToast from '../../components/Toasts/ShowLoadingToast';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const serverURL = process.env.REACT_APP_SERVER_URL;
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language || 'en');
    const { t } = useTranslation();

    const changeLanguage = (lng: string) => {
        showLoadingToast("Changing language...", 100)
        setTimeout(() => {
            setLanguage(lng);
            i18n.changeLanguage(lng);
        }, 1000)
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setLoading(true);
            // Add a delay of 1 second before navigating to "/home"
            setTimeout(async () => {
                setLoading(false);
                const loginSuccess = await UserService.login(username, password);

                if (loginSuccess) {
                    // Fetch the profile to set the current user in context
                    const userProfile = await UserService.getProfile();
                    setCurrentUser(userProfile);
                    if (userProfile.role.toString() !== "1" && userProfile.role.toString() !== "2") {
                        navigate('/tch/dashboard');
                    } else {
                        navigate('/gh/dashboard');
                    }
                }
            }, 1000);

        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        // Remove leading and trailing spaces
        const trimmedValue = value.trim();
        // Convert value to lowercase and remove spaces
        const sanitizedValue = trimmedValue.toLowerCase().replace(/\s/g, '');
        // Remove special characters using regex
        const sanitizedValueWithoutSpecialChars = sanitizedValue.replace(/[^\w]/g, '');
        // Update the state with the sanitized value
        setUsername(sanitizedValueWithoutSpecialChars);
    };

    return (
        <div className='min-h-screen p-8'>
            <Dropdown>
                <DropdownButton outline aria-label="More options" >
                    <EllipsisVerticalIcon /> Language 语言
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="top start">
                    <DropdownItem onClick={() => changeLanguage('en')} className={language === 'en' ? 'font-bold' : 'font-normal'}>
                        <DropdownLabel>
                            English
                        </DropdownLabel>
                        {(language === 'en') && <CheckIcon />}
                    </DropdownItem>
                    <DropdownItem onClick={() => changeLanguage('cn')} className={language === 'cn' ? 'font-bold' : 'font-normal'}>
                        <DropdownLabel>
                            中文
                        </DropdownLabel>
                        {(language === 'cn') && <CheckIcon />}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <div className="flex flex-1 flex-col justify-center px-6 py-24 lg:px-8">

                <div className="sm:mx-auto sm:w-full sm:max-w-sm grid justify-items-center">
                    <Avatar src="/images/Logo.jpg" className="bg-gray-300 text-gray-700 size-24" />
                    <h1 className="pt-6 pb-3 bg-gradient-to-r from-red-700 via-red-600 to-zinc-900 text-transparent bg-clip-text text-center font-bold text-4xl">VitalFlow</h1 >
                    <p className="text-zinc-600 text-center text-md font-medium">Grateful Healthcare Malaysia <br></br>马来西亚感恩康复理疗</p >


                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                {t('username')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="text"
                                    name="username"
                                    type="username"
                                    autoComplete="username"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6 px-2"
                                    onChange={handleUsernameChange} value={username}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                    {t('password')}
                                </label>

                            </div>
                            <div className="mt-2">
                                <div className="relative">
                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6 px-2"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-500 cursor-pointer focus:outline-none"
                                    >
                                        {showPassword ? (
                                            <EyeSlashIcon className="h-4 w-4 text-grey-300" />
                                        ) : (
                                            <EyeIcon className="h-4 w-4 text-grey-500" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {(!loading) ? <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-zinc-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                            >
                                {t('login')}
                            </button>

                                : <button type="button" className="flex w-full items-center justify-center rounded-md bg-zinc-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600" disabled>
                                    <Loading />
                                    <p className='ml-2'>{t('logging_in')}</p>
                                </button>}
                        </div>
                    </form>
                </div>

                <p className="mt-24 text-[12px] leading-6 text-gray-500 dark:text-gray-500 text-center text-bold">
                    ©VitalFlow (Grateful Healthcare Sdn. Bhd.) 2024 by Kingdom Codeworks Malaysia. All rights reserved.
                </p>
                {/* <p className="text-[12px] leading-6 text-gray-500 dark:text-gray-500 text-center text-bold">
                Server URL: {serverURL}
            </p> */}
            </div>
        </div>

    );
};

export default LoginPage;
