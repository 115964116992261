import clsx from 'clsx';
import { Link } from './Link';

export function Text({ className, fontSize, ...props }: React.ComponentPropsWithoutRef<'p'> & { fontSize?: string }) {
    return (
        <p
            data-slot="text"
            {...props}
            className={clsx(
                className,
                fontSize || 'text-sm',  // Default to 'text-base' if no fontSize is provided
                'text-zinc-500 sm:text-sm dark:text-zinc-400'
            )}
        />
    );
}

export function TextLink({ className, fontSize, ...props }: React.ComponentPropsWithoutRef<typeof Link> & { fontSize?: string }) {
    return (
        <Link
            {...props}
            className={clsx(
                className,
                fontSize || 'text-base',  // Default to 'text-base' if no fontSize is provided
                'text-zinc-950 underline decoration-zinc-950/50 dark:text-white dark:decoration-white/50'
            )}
        />
    );
}

export function Strong({ className, fontSize, ...props }: React.ComponentPropsWithoutRef<'strong'> & { fontSize?: string }) {
    return (
        <strong
            {...props}
            className={clsx(
                className,
                fontSize || 'text-lg',  // Default to 'text-lg' if no fontSize is provided
                'font-medium text-zinc-950 dark:text-white'
            )}
        />
    );
}

export function Code({ className, fontSize, ...props }: React.ComponentPropsWithoutRef<'code'> & { fontSize?: string }) {
    return (
        <code
            {...props}
            className={clsx(
                className,
                fontSize || 'text-sm',  // Default to 'text-sm' if no fontSize is provided
                'rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 font-medium text-zinc-950 dark:border-white/20 dark:bg-white/5 dark:text-white'
            )}
        />
    );
}
