import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CalendarIcon, ChevronLeftIcon } from '@heroicons/react/16/solid';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../../components/Description-List';
import { Avatar } from '../../../../../components/Avatar';
import Loading from '../../../../../components/Loading';
import useGHBracePatients from '../../../../../hooks/useGhBracePatient';
import dateFormat from 'dateformat';
import GHBraceDataService from '../../../../../services/ghbracedataService'; // Import the brace data service
import { GHBraceData } from '../../../../../models/GhBraceData';
import { Button } from '../../../../../components/Button';
import { useGHBraceData } from '../../../../../hooks/useGhBraceData';
import GHBracePatientImagesService from '../../../../../services/ghbracepatientimagesService';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../../components/Dialog';
import { useTranslation } from 'react-i18next';
import { Strong, Text } from '../../../../../components/Text';

interface GetImagesResponse {
    images: Image[]; // Assuming images is an array of Image objects
}

interface Image {
    id: string;
    image_data: string;
    // Other fields
}

interface ImageData {
    id: number;
    image_data: string;
    date: string;
    remarks: string;
}

const GHBracePatientIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { fetchPatientById, selectedPatient, loading, error } = useGHBracePatients();
    const { fetchDataIdByPatientId } = useGHBraceData();
    const [braceLoading, setBraceLoading] = useState(true);
    const [braceError, setBraceError] = useState<string | null>(null);
    const [braceDataId, setBraceDataId] = useState<number>();
    const navigate = useNavigate();
    const [images, setImages] = useState<ImageData[]>([]);
    const [isOpen, setIsOpen] = useState(false); // State to control dialog visibility
    const [isDeleting, setIsDeleting] = useState(false); // State to indicate deletion in progress
    const [imageToDelete, setImageToDelete] = useState<ImageData | null>(null); // State to track the image to be deleted
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('view')} ${t('patient')}`
    }, []);

    useEffect(() => {
        if (!id) return;
        const fetchPatientData = async () => {
            const result = await fetchPatientById(Number(id));
            if (result?.brace_data_id) {
                setBraceDataId(Number(result?.brace_data_id));
            }
        };

        const fetchPatientImage = async () => {
            try {
                const patientImageData = await GHBracePatientImagesService.getPatientImages(id);
                setImages(patientImageData.Success || []);
            } catch (err) {
                console.error('Failed to fetch brace patient images:', err);
            }
        };
        fetchPatientData();
        fetchPatientImage();
    }, [id, braceDataId]);

    const handleDeleteImageOnClick = (image: ImageData) => {
        setImageToDelete(image);
        setIsOpen(true); // Open the confirmation dialog
    };

    const handleDelete = async () => {
        if (!imageToDelete) return;

        setIsDeleting(true);
        try {
            await GHBracePatientImagesService.deletePatientImage(imageToDelete.id);
            setImages(prevImages => prevImages.filter(image => image.id !== imageToDelete.id));
            setIsOpen(false);
        } catch (err) {
            console.error(`Failed to delete image with ID ${imageToDelete.id}:`, err);
        } finally {
            setIsDeleting(false);
            setImageToDelete(null);
        }
    };

    const normalizeStringToKey = (str: string) => {
        // Convert spaces to underscores, make lowercase, and remove any other special characters.
        return str
            .toLowerCase()
            .replace(/\s+/g, '_')  // Replace spaces with underscores
            .replace(/[^\w\s]/g, '');  // Remove non-alphanumeric characters
    };

    if (loading && !selectedPatient) return <div className="py-32 text-center"><Loading /><p className='py-12'>Loading Patient's Data...</p></div>;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!selectedPatient) return <p className="py-16 text-center">Patient not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gh/braces/patients" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    {t('brace_patients')}
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={selectedPatient.fullname[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{selectedPatient.fullname}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>{t('created_on')}</span><span>{dateFormat(selectedPatient.created_time!, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className='flex flex-wrap items-end justify-between gap-4'>
                        {(braceDataId) && <Button outline onClick={() => navigate(`/gh/braces/data/view/${braceDataId}`)}>{t('view')}{t('brace_data')}</Button>}
                        <Button onClick={() => navigate(`/gh/braces/patients/edit/${id}`)}>{t('edit')}</Button>
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <Subheading>{t('patient_information')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('fullname')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.fullname}</DescriptionDetails>
                    <DescriptionTerm>{t('date_of_birth')}</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(selectedPatient.dob, "mmmm dd, yyyy") || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('gender')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.gender || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('diagnosis')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.scoliosis_type ? t(normalizeStringToKey(selectedPatient.scoliosis_type)) : '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('curve_pattern')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.curve_pattern ? t(normalizeStringToKey(selectedPatient.curve_pattern)) : '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('key_pattern')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.key_pattern || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('menarche_voice_change')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.menarche_voice_change || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('cobb_angle_thoracic')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.cobb_th || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('cobb_angle_lumbar')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.cobb_i || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('atr_thoracic')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.atr_th || '-'}</DescriptionDetails>
                    <DescriptionTerm>{t('atr_lumbar')}</DescriptionTerm>
                    <DescriptionDetails>{selectedPatient.atr_i || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>{t('patient_images')}</Subheading>
                <Divider className="mt-4" />
                <div className='grid grid-cols-3 md:-ml-4 lg:-ml-0 mt-4 gap-10'>
                    {images.length > 0 ? images.map((image: ImageData, i) => (
                        <div key={i} className='text-sm'>
                            <div className="flex flex-1 items-center justify-between">
                                <Text className="text-zinc-400" >Date: {dateFormat(image.date, "mmm dd, yyyy") || "-"}</Text>
                                <Button
                                    type="button"
                                    plain
                                    className="text-sm"
                                    onClick={() => handleDeleteImageOnClick(image)}
                                >
                                    {t('delete')}
                                </Button>
                            </div>
                            <Strong className="mt-4 font-bold text-xl">{image.remarks || "-"}</Strong>
                            <img
                                className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                src={`data:image/jpeg;base64,${image.image_data}`}
                                alt={`Image ${i + 1}`}
                            />
                        </div>
                    )) : (
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            No patient image found.
                        </label>
                    )}
                </div>
            </div>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>{t('delete')}{t('patient_images')}</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this image? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GHBracePatientIdPage;
