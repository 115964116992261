import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import GHBracePatientService from '../../../../../services/ghbracepatientService';
import { Radio, RadioField, RadioGroup } from '../../../../../components/Radio';
import { Label } from '../../../../../components/Fieldset';
import { GHBracePatient } from '../../../../../models/GhBracePatient';
import useGHBracePatients from '../../../../../hooks/useGhBracePatient';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface GHEditBracePatientPageProps {
    // No props needed
}

// Utility function to format date to yyyy-MM-dd
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);
    return date.toISOString().split('T')[0]; // Splits at 'T' and returns the date part
};

const GHEditBracePatientPage: React.FC<GHEditBracePatientPageProps> = () => {
    const { id } = useParams<{ id: string }>(); // Get patient ID from URL
    const navigate = useNavigate();
    const [patient, setPatient] = useState<GHBracePatient | null>(null);
    const { updatePatient } = useGHBracePatients();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('edit')} ${t('patient')}`
    }, []);

    useEffect(() => {
        const fetchPatient = async () => {
            try {
                // Fetch patient data from the API
                const response = await GHBracePatientService.getPatientById(Number(id));
                // Convert date fields to the correct format before setting the state
                if (response.dob) {
                    response.dob = formatDate(response.dob);
                }
                setPatient(response);
            } catch (error) {
                showErrorToast(`Failed to fetch patient data: ${error}`);
            }
        };
        fetchPatient();
    }, [id]);

    // Define options as an object with keys for each select field
    const OPTIONS = {
        scoliosisType: [
            "adolescent_idiopathic_scoliosis",
            "juvenile_idiopathic_scoliosis",
            "kyphosis_thoracolumbar",
            "infantile_idiopathic_scoliosis",
            "congenital_scoliosis",
            "neuromuscular_scoliosis",
            "scoliosis_in_marfans_syndrome",
            "scoliosis_in_neurofibromatosis",
            "scoliosis_in_other_aetiology",
            "kyphosis_thoracic",
            "kyphosis_lumbar"
        ],
        curvePattern: [
            "kyphosis_lumbar",
            "kyphosis_thoracic",
            "thoracic",
            "lumbar",
            "thoracolumbar",
            "double_major",
            "double_thoracic",
        ],
        keyPattern: [
            "KY-L",
            "KY-TH",
            "3CH",
            "3CTL",
            "3C",
            "3CL",
            "3CN",
            "4C",
            "4CL",
            "4CTL"
        ],
        menarcheVoiceChange: [
            "0", "3", "6", "9", "12", "15", "18", "21", "24", "27", "30", "More Than 30"
        ],
        tanner: ["1", "2", "3", "4", "5"],
        risser: ["0", "1", "2", "3", "4", "5"]
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setPatient((prev: any) => prev ? { ...prev, [name]: value } : prev);
    };

    const handleRadioChange = (value: string) => {
        setPatient((prev: any) => prev ? { ...prev, gender: value } : prev);
    };

    const validateForm = () => {
        if (!patient?.fullname) {
            showErrorToast('Full name is required');
            return false;
        }
        // Additional validation based on fields can be added here
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!patient || !id) return;

        if (!patient.fullname || !patient.dob || !patient.gender || !patient.reference_id || !patient.atr_i || !patient.atr_th || !patient.cobb_i || !patient.cobb_th || !patient.curve_pattern || !patient.key_pattern || !patient.menarche_voice_change || !patient.risser || !patient.tanner) {
            showErrorToast(`Please fill in all required fields before submit.`);
            return;
        }

        try {
            const toastId = showLoadingToast(`Updating ${patient.fullname} ...`);
            const res = await updatePatient(Number(id), patient);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/gh/braces/patients');
                showSuccessToast(`${patient.fullname} updated successfully`);
            } else {
                showErrorToast(`Error updating brace patient`);
            }
        } catch (err) {
            showErrorToast(`Failed to update patient: ${err}`);
        }
    };

    const getDefaultOption = (options: string[], currentValue?: string) => {
        return currentValue && options.includes(currentValue) ? currentValue : options[0];
    };

    const renderSelectOptions = (options: string[], translate: boolean = false) => {
        const uniqueOptions = Array.from(new Set(options)); // Remove duplicates

        return uniqueOptions.map(option => (
            <option key={option} value={option}>
                {translate ? t(option) : option} {/* Translate if required */}
            </option>
        ));
    };

    if (!patient) {
        return <div>Loading...</div>;
    }

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('edit')}{t('patient')}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!patient.fullname || !patient.dob || !patient.gender || !patient.reference_id || !patient.atr_i || !patient.atr_th || !patient.cobb_i || !patient.cobb_th || !patient.curve_pattern || !patient.key_pattern || !patient.menarche_voice_change || !patient.risser || !patient.tanner)}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('profile')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('profile_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('fullname')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={patient.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('date_of_birth')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="date"
                                        aria-label="Date of Birth"
                                        name="dob"
                                        value={patient.dob || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('gender')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <RadioGroup name="gender" value={patient.gender} onChange={handleRadioChange}>
                                        <RadioField>
                                            <Radio value='Male'></Radio><Label>{t('male')}</Label>
                                        </RadioField>
                                        <RadioField>
                                            <Radio value='Female'></Radio><Label>{t('female')}</Label>
                                        </RadioField>
                                    </RadioGroup>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='space-y-12'>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_diagnosis')}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                {t('patient_diagnosis_description')}
                            </p>
                        </div>
                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('patient_id')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="reference_id"
                                            name="reference_id"
                                            value={patient.reference_id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('diagnosis')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            id="scoliosis_type"
                                            name="scoliosis_type"
                                            autoComplete="scoliosis_type"
                                            onChange={handleChange}
                                            value={patient.scoliosis_type}
                                        >
                                            {renderSelectOptions(OPTIONS.scoliosisType, true)}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('curve_pattern')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            id="curve_pattern"
                                            name="curve_pattern"
                                            autoComplete="curve_pattern"
                                            onChange={handleChange}
                                            value={patient.curve_pattern}
                                        >
                                            {renderSelectOptions(OPTIONS.curvePattern, true)}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('key_pattern')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            id="key_pattern"
                                            name="key_pattern"
                                            autoComplete="key_pattern"
                                            onChange={handleChange}
                                            value={patient.key_pattern}
                                        >
                                            {renderSelectOptions(OPTIONS.keyPattern)}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('menarche_voice_change')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            id="menarche_voice_change"
                                            name="menarche_voice_change"
                                            autoComplete="menarche_voice_change"
                                            onChange={handleChange}
                                            value={patient.menarche_voice_change}
                                        >
                                            {renderSelectOptions(OPTIONS.menarcheVoiceChange)}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('tanner_stage')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            id="tanner"
                                            name="tanner"
                                            autoComplete="tanner"
                                            onChange={handleChange}
                                            value={patient.tanner}
                                        >
                                            {renderSelectOptions(OPTIONS.tanner)}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('risser_stage')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            id="risser"
                                            name="risser"
                                            autoComplete="risser"
                                            onChange={handleChange}
                                            value={patient.risser}
                                        >
                                            {renderSelectOptions(OPTIONS.risser)}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('cobb_angle_thoracic')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="cobb_th"
                                            name="cobb_th"
                                            value={patient.cobb_th}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('cobb_angle_lumbar')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="cobb_i"
                                            name="cobb_i"
                                            value={patient.cobb_i}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('atr_thoracic')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="atr_th"
                                            name="atr_th"
                                            value={patient.atr_th}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('atr_lumbar')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="atr_i"
                                            name="atr_i"
                                            value={patient.atr_i}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" disabled={(!patient.fullname || !patient.dob || !patient.gender || !patient.reference_id || !patient.atr_i || !patient.atr_th || !patient.cobb_i || !patient.cobb_th || !patient.curve_pattern || !patient.key_pattern || !patient.menarche_voice_change || !patient.risser || !patient.tanner)}>{t('save')}</Button>
                </div>
            </div>
        </form>
    );
};

export default GHEditBracePatientPage;
