// services/courseTypeService.ts
import axios from 'axios';
import { CourseType } from '../models/GhCourseTypes';

const API_URL = process.env.REACT_APP_SERVER_URL + '/ghcoursetypes';


export const courseTypeService = {
    // Fetch all course types
    getAllCourseTypes: async (): Promise<CourseType[]> => {
        try {
            const response = await axios.get(`${API_URL}/getAllCourseTypes`);
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching course types: ${error}`);
        }
    },

    // Fetch a course type by ID
    getCourseTypeById: async (id: number): Promise<CourseType> => {
        try {
            const response = await axios.get(`${API_URL}/getCourseTypeById/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Error fetching course type by ID: ${error}`);
        }
    },

    // Create a new course type
    addCourseType: async (courseTypeData: Omit<CourseType, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const response = await axios.post(`${API_URL}/add`, courseTypeData);
            return response.data;
        } catch (error) {
            throw new Error(`Error adding course type: ${error}`);
        }
    },

    // Update a course type
    updateCourseType: async (id: number, courseTypeData: Omit<CourseType, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const response = await axios.put(`${API_URL}/update/${id}`, courseTypeData);
            return response.data;
        } catch (error) {
            throw new Error(`Error updating course type: ${error}`);
        }
    },

    // Delete a course type
    deleteCourseType: async (id: number): Promise<void> => {
        try {
            await axios.delete(`${API_URL}/delete/${id}`);
        } catch (error) {
            throw new Error(`Error deleting course type: ${error}`);
        }
    }
};
