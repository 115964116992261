import { useState, useEffect } from 'react';
import { UserService } from '../services/userService';
import { User } from '../models/User';
import showErrorToast from '../components/Toasts/ShowErrorToast';

const useUserService = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [totalUsers, setTotalUsers] = useState<number>(0); // Total number of users

    const fetchUsers = async () => {
        setLoading(true);
        setError(null);
        try {
            const userList = await UserService.getAllUsers();
            setUsers(userList);
            setTotalUsers(userList.length);
        } catch (err) {
            setError('Failed to load users');
            showErrorToast(`Failed fetching all users ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const getUserById = async (id: string): Promise<User | null> => {
        setLoading(true);
        setError(null);
        try {
            const user = await UserService.getUserById(id);
            return user;
        } catch (err) {
            setError('Failed to load user by id');
            showErrorToast(`Failed fetching user by id: ${id} ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const deleteUser = async (id: string) => {
        setLoading(true);
        setError(null);
        try {
            await UserService.deleteUser(id);
            setUsers(users.filter((i) => BigInt(i.id) !== BigInt(id)));
        } catch (err) {
            setError('Failed to delete user');
            showErrorToast(`Failed deleting user: ${id} ${err}`);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    return { users, loading, error, totalUsers, fetchUsers, deleteUser, getUserById };
};

export default useUserService;
