import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tchPillowPatientService } from '../../../../../services/tchpillowpatientService';
import { TchPillowPatient } from '../../../../../models/TchPillowPatient';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Textarea } from '../../../../../components/Textarea';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import useTchPillowPatientService from '../../../../../hooks/useTchPillowPatient';
import { Checkbox, CheckboxField } from '../../../../../components/Checkbox';
import { Description, Label } from '../../../../../components/Fieldset';
import PillowPatientRefer from '../../../../../../public/images/PillowPatientRefer.png'
import { Radio, RadioField, RadioGroup } from '../../../../../components/Radio';
import { UserContext } from '../../../../../context/userContext';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface AddTchPillowPatient {
    fullname: string;
    email?: string;
    mobile_no?: string;
    street_address?: string;
    dob?: string;
    height?: string;
    weight?: string;
    gender?: string;
    sagittal_suture?: string;
    external_occipital_protuberance?: string;
    both_shoulders_align?: string;
    purchase_quantity?: string;
    remarks?: string;
    agent_id: bigint;
}

const AddTchPillowPatientPage: React.FC = () => {
    const navigate = useNavigate();
    const { createPillowPatient } = useTchPillowPatientService();
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();

    const [patient, setPatient] = useState<AddTchPillowPatient>({
        fullname: '',
        email: '',
        mobile_no: '',
        street_address: '',
        dob: '',
        height: '',
        weight: '',
        gender: '',
        sagittal_suture: '',
        external_occipital_protuberance: '',
        both_shoulders_align: '',
        purchase_quantity: '',
        remarks: '',
        agent_id: BigInt(0), // Replace with actual agent ID
    });

    useEffect(() => {
        document.title = `${t('create')} ${t('new')} ${t('pillow_buyers')} | ${t('patient')}`
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setPatient(prev => ({ ...prev, [name]: value }));
    };


    const handleRadioChange = (value: string) => {
        setPatient(prev => ({ ...prev, gender: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!patient) {
            return;
        }

        if (!patient.fullname || !patient.gender || !patient.mobile_no || !patient.dob || !patient.email || !patient.purchase_quantity) {
            showErrorToast(`Please fill in all required fields before submit`);
            return;
        }

        try {
            // Convert BigInt values to strings
            const patientToSubmit = {
                ...patient,
                agent_id: currentUser?.id.toString(),
            };
            const toastId = showLoadingToast(`Creating ${patient.fullname} ...`);
            const res = await createPillowPatient(patientToSubmit);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/tch/products/pillows/patients');
                showSuccessToast(`${patient.fullname} added successfully`);
            } else {
                showErrorToast(`Error creating pillow patients: ${res.message}`);
            }
        } catch (err) {
            showErrorToast(`Failed to add patient: ${err}`);
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('create')}{t('pillow_buyers')} | {t('patients')}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!patient.fullname || !patient.gender || !patient.mobile_no || !patient.dob || !patient.email || !patient.purchase_quantity)}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('profile')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('profile_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('fullname')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={patient.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('email')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={patient.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('mobile_no')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={patient.mobile_no}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('street_address')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Street Address"
                                        name="street_address"
                                        value={patient.street_address}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('date_of_birth')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="date"
                                        aria-label="Date of Birth"
                                        name="dob"
                                        value={patient.dob}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('height')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Height"
                                        name="height"
                                        value={patient.height}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('weight')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Weight"
                                        name="weight"
                                        value={patient.weight}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('gender')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <RadioGroup name="gender" value={patient.gender} onChange={handleRadioChange}>
                                        <RadioField>
                                            <Radio value='Male'></Radio><Label>{t('male')}</Label>
                                        </RadioField>
                                        <RadioField>
                                            <Radio value='Female'></Radio><Label>{t('female')}</Label>
                                        </RadioField>
                                    </RadioGroup>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('purchase_quantity')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Purchase Quantity"
                                        name="purchase_quantity"
                                        value={patient.purchase_quantity}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('remarks')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={patient.remarks}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='space-y-12'>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_information')}</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                {t('patient_information_description')}
                            </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="">
                                        <img
                                            src={'/images/PillowPatientRefer.png'}
                                            alt="Patient Measurement Info"
                                            className="w-full max-w-none rounded-xl ring-1 ring-gray-200 md:-ml-4 lg:-ml-0 mt-4"
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('sagittal_suture')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="Sagittal Suture"
                                            name="sagittal_suture"
                                            value={patient.sagittal_suture}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('external_occipital_protuberance')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="External Occipital Protuberance"
                                            name="external_occipital_protuberance"
                                            value={patient.external_occipital_protuberance}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>{t('both_shoulders_align')}</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="Both Shoulders Align"
                                            name="both_shoulders_align"
                                            value={patient.both_shoulders_align}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 my-6">
                        <Button type="reset" plain onClick={() => navigate(-1)}>
                            {t('cancel')}
                        </Button>
                        <Button type="submit" className='' disabled={(!patient.fullname || !patient.gender || !patient.mobile_no || !patient.dob || !patient.email || !patient.purchase_quantity)}>{t('save')}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddTchPillowPatientPage;
