import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Textarea } from '../../../../../components/Textarea';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../../components/Loading';
import useCourseTypes from '../../../../../hooks/useGhCourseTypes';
import { CourseType } from '../../../../../models/GhCourseTypes';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

const GHEditCourseTypePage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { updateCourseType, fetchCourseTypeById, loading, error } = useCourseTypes();
    const { t } = useTranslation()

    const [courseType, setCourseType] = useState<CourseType | null>(null);

    useEffect(() => {
        document.title = `${t('edit')} ${t('course_types')}`
    }, []);

    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedCourseType = await fetchCourseTypeById(Number(id));
                setCourseType(fetchedCourseType);
            } catch (err) {
                showErrorToast('Failed to fetch course details.');
            }
        };
        fetchCourseDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCourseType(prev => prev ? ({ ...prev, [name]: value }) : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!courseType) return;

        if (!courseType.course_name) {
            showErrorToast(`Please fill in all required fields before submit.`);
            return;
        }

        try {
            const toastId = showLoadingToast(`Updating ${courseType.course_name}...`);
            const res = await updateCourseType(Number(id!), courseType);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/gh/courses/types');
                showSuccessToast(`${courseType.course_name} updated successfully`);
            } else {
                showErrorToast(`Error updating course type`);
            }
        } catch (err) {
            showErrorToast('Failed to update customer.');
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;
    if (!courseType) return <p>Course not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('edit')}{t('course')}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!courseType.course_name)}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('course_info')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('course_info_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('course_name')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Course Name"
                                        name="course_name"
                                        value={courseType.course_name!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='' disabled={!courseType.course_name}>{t('save')}</Button>
                </div>
            </div>
        </form>
    );
};

export default GHEditCourseTypePage;
