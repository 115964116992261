import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Textarea } from '../../../../../components/Textarea';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../../components/Loading';
import { useCourseParticipants } from '../../../../../hooks/useGhCourseParticipants';
import { CourseParticipant } from '../../../../../models/GhCourseParticipants';
import { CourseParticipantImages } from '../../../../../models/ImagesModel';
import { Certificate } from 'crypto';
import useCourseTypes from '../../../../../hooks/useGhCourseTypes';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../components/Text';
import toast from 'react-hot-toast';

// Utility function to format date to yyyy-MM-dd based on local time
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);

    // Get the year, month, and day using local time
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
};

// Create a function to convert Base64 to Blob
const base64ToBlob = (base64: string, type = 'application/octet-stream') => {
    if (!base64) return null; // Return null if the base64 string is empty

    // Check if the base64 string has a prefix and strip it
    const base64Regex = /^data:(.+?);base64,(.+)$/;
    const matches = base64.match(base64Regex);

    if (matches) {
        base64 = matches[2]; // Take the actual base64 part
    }

    // Ensure padding for base64 string
    while (base64.length % 4 !== 0) {
        base64 += "=";
    }

    try {
        const byteCharacters = atob(base64); // Decode Base64 to binary string
        const byteNumbers = new Uint8Array(byteCharacters.length); // Create a byte array

        // Fill the byte array with binary data
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Create and return a new Blob object from the byte array
        return new Blob([byteNumbers], { type: type });
    } catch (error) {
        console.error("Error decoding Base64 string:", error);
        return null; // Return null if decoding fails
    }
};

const GHEditCourseParticipantPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { updateParticipant, fetchParticipantById, fetchParticipantImagesById, loading, error } = useCourseParticipants();
    const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
    const { courseTypes } = useCourseTypes();
    const { t } = useTranslation();

    const [courseParticipant, setCourseParticipant] = useState<Omit<CourseParticipant, 'id' | 'created_time' | 'updated_time'>>({
        course_type_id: Number(0),
        fullname: '',
        mobile_no: '',
        email: '',
        complete_date: '',
        certificate: null,
        certificate_2: null,
    });

    const [images, setImages] = useState<CourseParticipantImages | null>(null);
    const [newImages, setNewImages] = useState({
        certificate: courseParticipant.certificate,
        certificate_2: courseParticipant.certificate_2,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCourseParticipant(prev => ({ ...prev, [name]: value }));
    };

    // Handle file inputs
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const { files } = e.target;
        if (files && files[0]) {
            setFileNames((prevNames) => ({
                ...prevNames,
                [inputName]: files[0].name,
            }));
            setCourseParticipant((prevData) => ({
                ...prevData,
                [inputName]: files[0],
            }));
            setNewImages((prev) => ({
                ...prev, [inputName]: files[0]
            }));
        }
    };

    const removeFileChange = (inputName: string) => {
        if (inputName === 'certificate') {
            setImages({
                certificate: null,
                certificate_2: images!.certificate_2,
            });
        } else {
            setImages({
                certificate: images!.certificate,
                certificate_2: null,
            });
        }

        setCourseParticipant((prevData) => ({
            ...prevData,
            [inputName]: null,
        }));
        setNewImages((prev) => ({
            ...prev, [inputName]: null
        }));
    }

    // Map the courseTypes to selectOptions
    const selectOptions = courseTypes.map(course => ({
        value: course.id,
        label: course.course_name, // Handle missing course name
    }));

    const handleSelectCourseChange = (selectedOption: any) => {
        setCourseParticipant((prev) => ({ ...prev, course_type_id: selectedOption.value }));
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!courseParticipant) return;

        if (!courseParticipant.course_type_id || !courseParticipant.complete_date || !courseParticipant.email || !courseParticipant.fullname || !courseParticipant.mobile_no) {
            showErrorToast(`Please fill in all required fields before submit.`);
            return;
        }

        try {
            const toastId = showLoadingToast(`Creating ${courseParticipant.fullname} ...`);
            const res = await updateParticipant(Number(id!), courseParticipant, newImages);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/gh/courses/participants');
                showSuccessToast(`${courseParticipant.fullname} updated successfully`);
            } else {
                showErrorToast(`Error updating course participant`);
            }
        } catch (err) {
            showErrorToast(`Failed to update course: ${err}`);
        }
    };

    useEffect(() => {
        document.title = `${t('edit')} ${t('course_participants')}`
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const existingData = await fetchParticipantById(Number(id));
            // Convert date fields to the correct format before setting the state
            if (existingData.complete_date) {
                existingData.complete_date = formatDate(existingData.complete_date);
            }
            setCourseParticipant(existingData);
        };

        const fetchCertificate = async () => {
            const participantImages = await fetchParticipantImagesById(Number(id!));
            // Set images to the state directly, assuming the structure matches
            setImages({
                certificate: participantImages.certificate,
                certificate_2: participantImages.certificate_2,
            });

            // Assuming the fetched data has the image data in Base64 format
            const newImagesData = {
                certificate: participantImages.certificate ? base64ToBlob(participantImages.certificate, 'image/jpeg') : null,
                certificate_2: participantImages.certificate_2 ? base64ToBlob(participantImages.certificate_2, 'image/jpeg') : null,
            };

            setNewImages(newImagesData);
        };

        fetchData();
        fetchCertificate();
    }, [id]);


    if (loading && !courseParticipant) return <div className="py-32 text-center"><Loading /><p className='py-12'>Loading participant's data...</p></div>;
    if (error) return <p className="py-16 text-center text-red-700">{error}</p>;
    if (!courseParticipant) return <p className="py-16 text-center">Participant's data not found. Please try again.</p>;


    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('edit')}{t('participant')}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!courseParticipant.course_type_id || !courseParticipant.complete_date || !courseParticipant.email || !courseParticipant.fullname || !courseParticipant.mobile_no)}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('course_participant_info')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('course_participant_info_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>{t('course')}</Subheading>
                            <Select
                                id="patient_id"
                                name="patient_id"
                                options={selectOptions}
                                className="block w-full rounded-md border-0 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                placeholder="Select Course"
                                onChange={handleSelectCourseChange}
                                value={selectOptions.find(option => option.value === courseParticipant.course_type_id)}
                            />
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('fullname')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Fullname"
                                        name="fullname"
                                        value={courseParticipant.fullname!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('mobile_no')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={courseParticipant.mobile_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('email')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Email"
                                        name="email"
                                        value={courseParticipant.email!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('completed_date')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Completed Date"
                                        name="complete_date"
                                        value={courseParticipant.complete_date!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                        {['certificate', 'certificate_2'].map((name, index) => (
                            <div key={index} className="sm:col-span-full">
                                <section>
                                    <Subheading>{`${name.replace(/_/g, '').replace(/\b\w/g, char => char.toUpperCase())}`}</Subheading>
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6 col-span-2">
                                            <div className="text-center self-center">
                                                <div className="mt-4 text-sm leading-6 text-gray-600">
                                                    <label
                                                        htmlFor={`file-upload-${name}`}
                                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 "
                                                    >
                                                        <span>{t('upload_an_image')}</span>
                                                        <input
                                                            id={`file-upload-${name}`}
                                                            name={name}
                                                            type="file"
                                                            className="sr-only"
                                                            accept=".jpg, .png, .jpeg, .pdf"
                                                            onChange={(e) => handleFileChange(e, `${name}`)}
                                                        />
                                                    </label>

                                                </div>
                                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG up to 8MB</p>
                                            </div>
                                        </div>
                                        <div>
                                            {images?.[name] ? (
                                                <div>
                                                    {
                                                        images?.[name] && (
                                                            <div>
                                                                <img
                                                                    className="w-full max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                                                    src={images?.[name]!}
                                                                    alt={`${name.replace(/_/g, '').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (<Text className='py-5 justify-center'>No images available</Text>)}
                                        </div>
                                    </div>
                                    {fileNames[`${name}`] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames[`${name}`]}</strong>
                                        </p>
                                    )}
                                    <Button plain className='my-4 text-red-300' onClick={() => removeFileChange(`${name}`)}>Remove Certificate</Button>
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='' disabled={(!courseParticipant.course_type_id || !courseParticipant.complete_date || !courseParticipant.email || !courseParticipant.fullname || !courseParticipant.mobile_no)}>{t('save')}</Button>
                </div>
            </div>
        </form>
    );
};

export default GHEditCourseParticipantPage;
