import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Textarea } from '../../../../../components/Textarea';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../../components/Loading';
import { useCourseParticipants } from '../../../../../hooks/useGhCourseParticipants';
import useCourseTypes from '../../../../../hooks/useGhCourseTypes';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface AddCourseParticipant {
    course_type_id: number | null;
    fullname: string;
    mobile_no?: string;
    email?: string;
    complete_date?: string | null;
}

const GHAddCourseParticipantPage = () => {
    const navigate = useNavigate();
    const { createParticipant } = useCourseParticipants();
    const { courseTypes, loading, error } = useCourseTypes();
    const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
    const { t } = useTranslation();

    const [courseParticipant, setCourseParticipant] = useState<AddCourseParticipant>({
        course_type_id: Number(0),
        fullname: '',
        mobile_no: '',
        email: '',
        complete_date: '',
    });

    const [images, setImages] = useState({
        certificate: null,
        certificate_2: null,
    });

    useEffect(() => {
        document.title = `${t('create')} ${t('new')} ${t('course_participants')}`
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCourseParticipant(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const file = e.target.files![0];
        if (file) {
            setFileNames((prevNames) => ({
                ...prevNames,
                [inputName]: file.name,
            }));
        }
        const { name, files } = e.target;
        if (files && files[0]) {
            setCourseParticipant((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
            setImages((prev) => ({ ...prev, [name]: file }));
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!courseParticipant) return;

        if (!courseParticipant.course_type_id || !courseParticipant.complete_date || !courseParticipant.email || !courseParticipant.fullname || !courseParticipant.mobile_no) {
            showErrorToast(`Please fill in all required fields before submit.`);
            return;
        }

        try {
            const toastId = showLoadingToast(`Creating ${courseParticipant.fullname} ...`);
            const res = await createParticipant(courseParticipant, images);

            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/gh/courses/participants');
                showSuccessToast(`${courseParticipant.fullname} added successfully`);
            } else {
                showErrorToast(`Error creating course participant`);
            }
        } catch (err) {
            showErrorToast(`Failed to add course: ${err}`);
        }
    };

    const [selectedCourseType, setSelectedCourseType] = useState<number | undefined>(undefined);

    // Map the courseTypes to selectOptions
    const selectOptions = courseTypes.map(course => ({
        value: course.id,
        label: course.course_name, // Handle missing course name
    }));

    const handleSelectCourseChange = (selectedOption: any) => {
        setCourseParticipant((prev) => ({ ...prev, course_type_id: selectedOption.value }));
    };

    // Set the default selected course type to the first option once courseTypes are loaded
    useEffect(() => {
        if (courseTypes.length > 0 && selectedCourseType === undefined) {
            setCourseParticipant((prev) => ({ ...prev, course_type_id: courseTypes[0].id }));
        }
    }, [courseTypes, selectedCourseType]);

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('create')}{t('participant')}</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!courseParticipant.course_type_id || !courseParticipant.complete_date || !courseParticipant.email || !courseParticipant.fullname || !courseParticipant.mobile_no)}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('course_participant_info')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('course_participant_info_description')}
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>{t('course')}</Subheading>
                            <Select
                                id="patient_id"
                                name="patient_id"
                                options={selectOptions}
                                className="block w-full rounded-md border-0 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-[#182235]"
                                placeholder="Select Course"
                                onChange={handleSelectCourseChange}
                            />
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('fullname')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Fullname"
                                        name="fullname"
                                        value={courseParticipant.fullname!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('mobile_no')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={courseParticipant.mobile_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('email')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Email"
                                        name="email"
                                        value={courseParticipant.email!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t('completed_date')}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Completed Date"
                                        name="complete_date"
                                        value={courseParticipant.complete_date!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className=''>
                                <div className="col-span-full">
                                    <Subheading>{t('certificate')}</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                        <div className="text-center">
                                            <div className="mt-4 text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload-certificate"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500"
                                                >
                                                    <span>{t('upload_an_image')}</span>
                                                    <input
                                                        id="file-upload-certificate"
                                                        name="certificate"
                                                        type="file"
                                                        accept=".jpg, .png, .jpeg, .pdf"
                                                        onChange={(e) => handleFileChange(e, 'certificate')}
                                                        className="sr-only"
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">JPG, PNG, JPEG, PDF up to 10MB</p>
                                        </div>

                                    </div>
                                    {fileNames['certificate'] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames.certificate}</strong>
                                        </p>
                                    )}
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className=''>
                                <div className="col-span-full">
                                    <Subheading>{t('certificate_2')}</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                        <div className="text-center">
                                            <div className="mt-4 text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload-certificate_2"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500"
                                                >
                                                    <span>{t('upload_an_image')}</span>
                                                    <input
                                                        id="file-upload-certificate_2"
                                                        name="certificate_2"
                                                        type="file"
                                                        accept=".jpg, .png, .jpeg, .pdf"
                                                        onChange={(e) => handleFileChange(e, 'certificate_2')}
                                                        className="sr-only"
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">JPG, PNG, JPEG, PDF up to 10MB</p>
                                        </div>

                                    </div>
                                    {fileNames['certificate_2'] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames.certificate_2}</strong>
                                        </p>
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='' disabled={(!courseParticipant.course_type_id || !courseParticipant.complete_date || !courseParticipant.email || !courseParticipant.fullname || !courseParticipant.mobile_no)}>Save</Button>
                </div>
            </div>
        </form>
    );
};

export default GHAddCourseParticipantPage;
