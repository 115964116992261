import { useState, useEffect } from 'react';
import { GHBraceDataService } from '../services/ghbracedataService';
import { GHBraceData } from '../models/GhBraceData';
import { BraceDataImages, GetImagesResponse } from '../models/ImagesModel';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export const useGHBraceData = () => {
    const [data, setData] = useState<GHBraceData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch all data
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await GHBraceDataService.getAll();
            setData(result);
            setLoading(false);
        } catch (err) {
            showErrorToast(`Failed fetching all brace data ${error}`);
            setError('Error fetching GHBraceData');
            setLoading(false);
        }
    };

    const fetchDataIdByPatientId = async (id: number) => {
        try {
            setLoading(true);
            const result = await GHBraceDataService.getIdByPatientId(id);
            return result;
        } catch (err) {
            showErrorToast(`Failed fetching brace data id by id: ${id} ${error}`);
            setError(`Error fetching GHBraceData with Patient ID: ${id}`);
            setLoading(false);
            throw err;
        }
    };

    const fetchDataByPatientId = async (id: number) => {
        try {
            setLoading(true);
            const result = await GHBraceDataService.getByPatientId(id);
            return result;
        } catch (err) {
            showErrorToast(`Failed fetching brace data by patient id: ${id} ${error}`);
            setError(`Error fetching GHBraceData with Patient ID: ${id}`);
            setLoading(false);
            throw err;
        }
    };

    const fetchDataById = async (id: number) => {
        try {
            setLoading(true);
            const result = await GHBraceDataService.getById(id);
            return result;
        } catch (err) {
            showErrorToast(`Failed fetching brace data by id: ${id} ${error}`);
            setError(`Error fetching GHBraceData with ID: ${id}`);
            setLoading(false);
            throw err;
        }
    };

    const fetchDataImagesById = async (id: number) => {
        try {
            setLoading(true);
            const result: GetImagesResponse = await GHBraceDataService.getImagesById(id);
            return result; // Ensure you return the result
        } catch (err) {
            showErrorToast(`Failed fetching brace data images by id: ${id} ${error}`);
            setError(`Error fetching GHBraceData Images with ID: ${id}`);
            setLoading(false);
            throw err;
        }
    };

    const createData = async (newData: Omit<GHBraceData, 'id' | 'created_time' | 'updated_time'>, images: any) => {
        try {
            setLoading(true);
            const newBraceDataRes = await GHBraceDataService.create(newData, images);
            setLoading(false);
            return newBraceDataRes;
        } catch (err) {
            showErrorToast(`Failed creating brace data ${error}`);
            setError('Error creating new GHBraceData');
            setLoading(false);
        }
    };

    const updateData = async (id: number, updatedData: Omit<GHBraceData, 'id' | 'created_time' | 'updated_time'>, updatedImages: any) => {
        try {
            setLoading(true);
            const result = await GHBraceDataService.update(id, updatedData, updatedImages);
            setLoading(false);
            return result;
        } catch (err) {
            showErrorToast(`Failed updating brace data: ${id} ${error}`);
            setError(`Error updating GHBraceData with ID: ${id}`);
            setLoading(false);
        }
    };

    const deleteData = async (id: number) => {
        try {
            setLoading(true);
            await GHBraceDataService.delete(id);
            setData(data.filter((item) => item.id !== BigInt(id)));
            setLoading(false);
        } catch (err) {
            showErrorToast(`Failed deleting brace data ${error}`);
            setError(`Error deleting GHBraceData with ID: ${id}`);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { data, loading, error, fetchData, fetchDataIdByPatientId, fetchDataByPatientId, fetchDataById, fetchDataImagesById, createData, updateData, deleteData };
};
