// src/services/tchPillowPatientService.ts

import axios from 'axios';

const API_URL = process.env.REACT_APP_SERVER_URL + '/tchpillowpatients';

export const tchPillowPatientService = {

    getAllPillowPatients: async () => {
        const response = await axios.get(`${API_URL}/getAllPillowPatients`);
        return response.data;
    },

    getPillowPatientById: async (id: string) => {
        const response = await axios.get(`${API_URL}/getPillowPatientsById/${id}`);
        return response.data;
    },

    createPillowPatient: async (pillowPatientData: any) => {
        const response = await axios.post(`${API_URL}/add`, pillowPatientData);
        return response.data;
    },

    updatePillowPatient: async (id: string, pillowPatientData: any) => {
        const response = await axios.put(`${API_URL}/update/${id}`, pillowPatientData);
        return response.data;
    },

    deletePillowPatient: async (id: string) => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};
