// hooks/useCourseTypes.ts
import { useState, useEffect } from 'react';
import { CourseType } from '../models/GhCourseTypes';
import { courseTypeService } from '../services/ghcoursetypesService';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export default function useCourseTypes() {
    const [courseTypes, setCourseTypes] = useState<CourseType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [totalCourseTypes, setTotalCourseTypes] = useState<number>(0);

    // Fetch all course types
    const fetchCourseTypes = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await courseTypeService.getAllCourseTypes();
            setCourseTypes(data);
            setTotalCourseTypes(data.length);
        } catch (err) {
            setError('Error while fetching course types.');
            showErrorToast(`Failed fetching all course types ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchCourseTypeById = async (id: number) => {
        setLoading(true);
        setError(null);

        try {
            const result = await courseTypeService.getCourseTypeById(id);
            return result;
        } catch (err) {
            setError(`Error while fetching course type ${id}`);
            showErrorToast(`Failed fetching course type by id: ${id} ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Add a course type
    const createCourseType = async (courseTypeData: Omit<CourseType, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);

        try {
            const newCourseTypeRes = await courseTypeService.addCourseType(courseTypeData);
            setCourseTypes([...courseTypes, newCourseTypeRes]);
            return newCourseTypeRes;
        } catch (err) {
            setError(`Error while adding course type ${courseTypeData.course_name}`);
            showErrorToast(`Failed creating course type ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Update a course type
    const updateCourseType = async (id: number, updatedData: Omit<CourseType, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);

        try {
            const updatedCourseTypeRes = await courseTypeService.updateCourseType(id, updatedData);
            setCourseTypes(courseTypes.map(ct => (ct.id === id ? updatedCourseTypeRes : ct)));
            return updatedCourseTypeRes;
        } catch (err) {
            setError(`Error while updating course type ${updatedData.course_name}`);
            showErrorToast(`Failed updating course type ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const deleteCourseType = async (id: number) => {
        setLoading(true);
        setError(null);

        try {
            await courseTypeService.deleteCourseType(id);
            setCourseTypes(courseTypes.filter(ct => ct.id !== id));
        } catch (err) {
            setError(`Error while deleting course type ${id}`);
            showErrorToast(`Failed deleting course type ${err}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCourseTypes();
    }, []);

    return {
        courseTypes,
        loading,
        error,
        fetchCourseTypes,
        fetchCourseTypeById,
        createCourseType,
        updateCourseType,
        deleteCourseType,
        totalCourseTypes,
    };
}
