// src/hooks/useTchPillowPatientService.ts

import { useState, useEffect } from 'react';
import { tchPillowPatientService } from '../services/tchpillowpatientService';
import showErrorToast from '../components/Toasts/ShowErrorToast';

const useTchPillowPatientService = () => {
    const [pillowPatients, setPillowPatients] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchPillowPatients = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await tchPillowPatientService.getAllPillowPatients();
            setPillowPatients(data);
        } catch (err) {
            setError('Failed to load pillow patients');
            showErrorToast(`Failed fetching all pillow patients ${err}`);
        } finally {
            setLoading(false);
        }
    };


    const fetchPillowPatientById = async (id: string) => {
        setLoading(true);
        setError(null);

        try {
            const pillowPatient = await tchPillowPatientService.getPillowPatientById(id);
            return pillowPatient;
        } catch (err) {
            setError('Failed to load pillow patient');
            showErrorToast(`Failed fetching pillow patients by id: ${id} ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createPillowPatient = async (pillowPatientData: any) => {
        setLoading(true);
        setError(null);

        try {
            const newPillowPatient = await tchPillowPatientService.createPillowPatient(pillowPatientData);
            setPillowPatients(prev => [...prev, newPillowPatient]);
            return newPillowPatient;
        } catch (err) {
            setError('Failed to create pillow patient');
            showErrorToast(`Failed creating pillow patient ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updatePillowPatient = async (id: string, pillowPatientData: any) => {
        setLoading(true);
        setError(null);

        try {
            const updatedPillowPatient = await tchPillowPatientService.updatePillowPatient(id, pillowPatientData);
            setPillowPatients(prev =>
                prev.map(patient => patient.id === id ? updatedPillowPatient : patient)
            );
            return updatedPillowPatient;
        } catch (err) {
            setError('Failed to update pillow patient');
            showErrorToast(`Failed updating pillow patients ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deletePillowPatient = async (id: string) => {
        setLoading(true);
        setError(null);

        try {
            await tchPillowPatientService.deletePillowPatient(id);
            setPillowPatients(prev => prev.filter(patient => patient.id !== id));
        } catch (err) {
            setError('Failed to delete pillow patient');
            showErrorToast(`Failed deleting pillow patients ${err}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPillowPatients();
    }, []);


    return {
        pillowPatients,
        loading,
        error,
        fetchPillowPatientById,
        createPillowPatient,
        updatePillowPatient,
        deletePillowPatient
    };
};

export default useTchPillowPatientService;

