import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/userContext';
import { Heading, Subheading } from '../../../components/Heading';
import { Select } from '../../../components/Select';
import { Badge } from '../../../components/Badge';
import { useTranslation } from 'react-i18next';
import useGHBracePatients from '../../../hooks/useGhBracePatient';
import useUserService from '../../../hooks/useUserService';
import { useCourseParticipants } from '../../../hooks/useGhCourseParticipants';
import useCourseTypes from '../../../hooks/useGhCourseTypes';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { Text } from '../../../components/Text';
import { useGHBraceData } from '../../../hooks/useGhBraceData';

export function Stat({ title, value, change, period }: { title: string; value: string; change: string; period: string }) {
    return (
        <div className='bg-zinc-100 p-6 rounded-md ring-1 ring-zinc-200'>
            <div className="text-lg/6 font-medium sm:text-sm/6">{title}</div>
            <div className="mt-3 text-3xl/8 font-semibold sm:text-2xl/8">{value}</div>
            <div className="mt-3 text-sm/6 sm:text-xs/6">
                <Badge color={change.startsWith('+') ? 'lime' : 'pink'}>{change}</Badge>{' '}
                <span className="text-zinc-500">from {period}</span>
            </div>
        </div>
    )
}

const GhDashboard: React.FC = () => {
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();
    const { patients } = useGHBracePatients();
    const { users } = useUserService();
    const { participants } = useCourseParticipants();
    const { courseTypes } = useCourseTypes();
    const { data } = useGHBraceData();
    const [period, setPeriod] = useState<string>('last week');

    useEffect(() => {
        document.title = `${t('gh_dashboard')}`
    }, []);

    // Get current hour to set greeting message
    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) return t('good_morning');
        if (currentHour < 18) return t('good_afternoon');
        return t('good_evening');
    };

    // Helper function to filter by selected period
    const filterByPeriod = (data: any[], period: string) => {
        const now = new Date();
        const startDate = new Date();

        if (period === 'last week') startDate.setDate(now.getDate() - 7);
        else if (period === 'last two') startDate.setDate(now.getDate() - 14);
        else if (period === 'last month') startDate.setMonth(now.getMonth() - 1);

        return data.filter(item => new Date(item.date) >= startDate);
    };

    // Calculate changes
    const calculateChange = (data: any[], period: string) => {
        const filteredData = filterByPeriod(data, period);
        const currentPeriodCount = filteredData.length;
        const previousPeriodCount = data.length - currentPeriodCount;

        if (previousPeriodCount === 0) return '0%';

        const change = ((currentPeriodCount - previousPeriodCount) / previousPeriodCount) * 100;
        return `${change > 0 ? '+' : ''}${change.toFixed(2)}%`;
    };

    return (
        <>
            <Heading>{getGreeting()}, {currentUser ? currentUser.fullname : "Guest"}</Heading>
            <div className="mt-8 flex items-end justify-between">
                <Subheading>{t('dashboard')}</Subheading>
                <div>
                    <Select
                        name="period"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)} // Update period state
                    >
                        <option value="last week">{t('last_week')}</option>
                        <option value="last two weeks">{t('last_two_week')}</option>
                        <option value="last month">{t('last_month')}</option>
                    </Select>
                </div>
            </div>
            <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-3">
                <Stat title={t("total_brace_patients")} value={`${patients.length.toString()} ${t('patients')}`} change={calculateChange(patients, period)} period={period} />
                <Stat title={t("total_brace_data")} value={`${data.length.toString()} ${t('brace_data')}`} change={calculateChange(data, period)} period={period} />
            </div>

            <div>
                <Subheading className="mt-14">{t('brace_patients')}</Subheading>
                {(patients.length > 0) ? <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>{t('fullname')}</TableHeader>
                            <TableHeader>{t('patient_id')}</TableHeader>
                            <TableHeader>{t('gender')}</TableHeader>
                            <TableHeader className="text-right">{t('created_on')}</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {patients.map((patient) => (
                            <TableRow key={patient.id} title={`${patient.fullname}`}>
                                <TableCell>{patient.fullname}</TableCell>
                                <TableCell className="text-zinc-500">{patient.reference_id}</TableCell>
                                <TableCell className="text-zinc-500">{t(patient.gender?.toString()!)}</TableCell>
                                <TableCell className="text-right">{dateFormat(patient.created_time?.toString(), "mmmm dd, yyyy")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table> : <Text className='py-12'>No patients found.</Text>}
            </div>
        </>
    );
};

export default GhDashboard;
