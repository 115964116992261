import axios from 'axios';

// Define the base URL for the API
const API_URL = process.env.REACT_APP_SERVER_URL + '/tchpillowpatientimages';


// Define interfaces for the expected response data
interface UploadResponse {
    Success?: string;
    Error?: string;
}

interface ImageData {
    id: number;
    image_data: string;
}

interface GetImagesResponse {
    Success?: ImageData[];
    Error?: string;
}

interface DeleteResponse {
    Success?: string;
    Error?: string;
}

// Service definition
const tchPillowPatientImagesService = {

    async uploadPatientImages(patientId: string, files: File[]): Promise<UploadResponse> {
        try {
            // Prepare form data
            const formData = new FormData();
            formData.append('patient_id', patientId);

            files.forEach((file) => {
                formData.append('image_data', file);
            });

            // Make POST request to upload images
            const response = await axios.post<UploadResponse>(`${API_URL}/uploadPatientImages_Pillow`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error uploading patient images:', error);
            throw error;
        }
    },

    async getPatientImages(patientId: string): Promise<GetImagesResponse> {
        try {
            // Make GET request to retrieve patient images
            const response = await axios.get<GetImagesResponse>(`${API_URL}/getPatientImages_Pillow/${patientId}`);
            return response.data;
        } catch (error) {
            console.error('Error retrieving patient images:', error);
            throw error;
        }
    },

    async deletePatientImage(imageId: number): Promise<DeleteResponse> {
        try {
            // Make DELETE request to delete the image
            const response = await axios.delete<DeleteResponse>(`${API_URL}/deletePatientImage/${imageId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting patient image:', error);
            throw error;
        }
    }
};

export default tchPillowPatientImagesService;
