import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";

const AccessDenied = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('access_denied')
    }, []);

    const dashboardOnClick = () => {
        if (currentUser!.role?.toString() !== "1" && currentUser!.role?.toString() !== "2") {
            navigate('/tch/dashboard');
        } else {
            navigate('/gh/dashboard');
        }
    }

    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-red-600">{t('error')} 403 - {t('access_denied')}</p>
                <h1 className="mt-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t('access_denied')}</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">{t('access_denied_description')}</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <button
                        onClick={dashboardOnClick}
                        className="rounded-md bg-zinc-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                    >
                        Go back to Dashboard
                    </button>
                </div>
            </div>
        </main>
    );
}

export default AccessDenied;