import React, { useEffect, useState } from 'react';
import { EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { TchPillowPatient } from '../../../../models/TchPillowPatient';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../../components/Dropdown';
import { Badge } from '../../../../components/Badge';
import { Link } from '../../../../components/Link';
import { Button } from '../../../../components/Button';
import { Heading } from '../../../../components/Heading';
import { Input, InputGroup } from '../../../../components/Input';
import { Divider } from '../../../../components/Divider';
import { UserService } from '../../../../services/userService'; // Import the UserService
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import Pagination from '../../../../components/Pagination'; // Import the Pagination component
import useUserService from '../../../../hooks/useUserService';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import useTchPillowPatientService from '../../../../hooks/useTchPillowPatient';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';

const TchPillowPatientPage: React.FC = () => {
    const navigate = useNavigate();
    const { pillowPatients, loading, error, deletePillowPatient } = useTchPillowPatientService();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredPatients, setFilteredPatients] = useState<TchPillowPatient[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [patientToDelete, setPatientToDelete] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const { t } = useTranslation();

    const patientsPerPage = 10;

    useEffect(() => {
        if (pillowPatients) {
            const filtered = pillowPatients.filter(patient =>
                patient.fullname.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredPatients(filtered);
            setTotalPages(Math.ceil(filtered.length / patientsPerPage));
        }
    }, [pillowPatients, searchQuery]);

    const currentPatients = filteredPatients.slice(
        (currentPage - 1) * patientsPerPage,
        currentPage * patientsPerPage
    );

    useEffect(() => {
        document.title = `${t('pillow_buyers')} | ${t('patient')}`
    }, []);

    const handleOpenDeleteDialog = (id: string) => {
        setPatientToDelete(id);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (patientToDelete) {
            setIsDeleting(true);
            await deletePillowPatient(patientToDelete);
            setIsDeleting(false);
            setIsOpen(false);
            setPatientToDelete(null);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>{t('pillow_buyers')}</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder={`${t('search')} ${t('pillow_buyers')}${String.fromCharCode(8230)}`}
                                    value={searchQuery}
                                    onChange={(e: any) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button outline onClick={() => navigate('/tch/products/pillows/patients/images/add')}>{t('create')} {t('patient_images')}</Button>
                <Button onClick={() => navigate('/tch/products/pillows/patients/add')}>{t('create')} {t('new')} {t('pillow_buyers')}</Button>
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {!loading && !error && currentPatients.length === 0 && <p className='py-16 text-center'>No patients found.</p>}
                {!loading && !error && currentPatients.map((patient, index) => (
                    <li key={patient.id.toString()}>
                        <Divider soft={index > 0} />
                        <div className="flex items-center justify-between">
                            <div className="flex gap-6 py-5">
                                <Avatar initials={patient.fullname[0]} className="bg-zinc-200 text-gray-700 size-12" />
                                <div className="">
                                    <div className="text-base/6 font-semibold">
                                        <Link href={`/tch/products/pillows/patients/view/${patient.id.toString()}`}>{patient.fullname}</Link>
                                    </div>
                                    <div className="text-xs/6 text-zinc-600">
                                        {t('date_of_birth')} : {patient.dob ? dateFormat(patient.dob.toString(), "mmmm dd, yyyy") : "N/A"}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Badge className="max-sm:hidden" color='lime'>
                                    {t('purchase_quantity')} : {patient.purchase_quantity}
                                </Badge>
                                <Dropdown>
                                    <DropdownButton plain aria-label="More options">
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                        <DropdownItem onClick={() => navigate(`/tch/products/pillows/patients/view/${patient.id.toString()}`)}>{t('view')}</DropdownItem>
                                        <DropdownItem onClick={() => navigate(`/tch/products/pillows/patients/edit/${patient.id.toString()}`)}>{t('edit')}</DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDeleteDialog(patient.id.toString())}>{t('delete')}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                totalResult={filteredPatients.length}
                dataName={t('patients')}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Patient</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this patient? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TchPillowPatientPage;
