import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Divider } from '../../../../../components/Divider';
import { Input } from '../../../../../components/Input';
import { Textarea } from '../../../../../components/Textarea';
import { Select } from '../../../../../components/Select';
import { Button } from '../../../../../components/Button';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../../components/Loading';
import useCourseTypes from '../../../../../hooks/useGhCourseTypes';
import { CalendarIcon, ChevronLeftIcon } from '@heroicons/react/16/solid';
import { Avatar } from '../../../../../components/Avatar';
import { CourseType } from '../../../../../models/GhCourseTypes';
import dateFormat from 'dateformat';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../../components/Description-List';
import { useTranslation } from 'react-i18next';

const GHViewCourseTypeIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const [courseType, setCourseType] = useState<CourseType | null>(null);
    const { fetchCourseTypeById, loading, error } = useCourseTypes();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${t('view')} ${t('course_types')}`
    }, []);

    useEffect(() => {
        const fetchCourseDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedCourseType = await fetchCourseTypeById(Number(id));
                setCourseType(fetchedCourseType);
            } catch (err) {
                showErrorToast('Failed to fetch course details.');
            }
        };
        fetchCourseDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!courseType) return <p className='py-16 text-center'>Course not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gh/courses/types" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    {t('course_types')}
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={courseType.course_name![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{courseType.course_name}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>{t('created_on')}</span><span>{dateFormat(courseType.created_time!, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gh/courses/types/edit/${courseType.id}`)}>{t('edit')}</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>{t('course_info')}</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>{t('course_name')}</DescriptionTerm>
                    <DescriptionDetails>{courseType.course_name}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default GHViewCourseTypeIdPage;
