import axios from 'axios';
import { GhBracePatientImages } from '../models/GhBracePatientImages';

// Define the base URL for the API
const API_URL = process.env.REACT_APP_SERVER_URL + '/ghbracepatientimages';


// Define interfaces for the expected response data
interface UploadResponse {
    Success?: string;
    Error?: string;
}

interface ImageData {
    id: number;
    image_data: string;
    date: string;
    remarks: string;
}

interface GetImagesResponse {
    Success?: ImageData[];
    Error?: string;
}

interface DeleteResponse {
    Success?: string;
    Error?: string;
}

// Service definition
const GHBracePatientImagesService = {

    async uploadPatientImages(data: GhBracePatientImages, files: File[]): Promise<UploadResponse> {
        try {
            // Prepare form data
            const formData = new FormData();
            formData.append('brace_patient_id', data.brace_patient_id.toString());
            formData.append('date', data.date?.toString()!);
            formData.append('remarks', data.remarks?.toString()!);

            files.forEach((file) => {
                formData.append('image_data', file);
            });

            // Make POST request to upload images
            const response = await axios.post<UploadResponse>(`${API_URL}/uploadimages`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error uploading patient images:', error);
            throw error;
        }
    },

    async getPatientImages(patientId: string): Promise<GetImagesResponse> {
        try {
            // Make GET request to retrieve patient images
            const response = await axios.get<GetImagesResponse>(`${API_URL}/getimages/${patientId}`);
            return response.data;
        } catch (error) {
            console.error('Error retrieving patient images:', error);
            throw error;
        }
    },

    async deletePatientImage(imageId: number): Promise<DeleteResponse> {
        try {
            // Make DELETE request to delete the image
            const response = await axios.delete<DeleteResponse>(`${API_URL}/deleteimage/${imageId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting patient image:', error);
            throw error;
        }
    }
};

export default GHBracePatientImagesService;
