// frontend/hooks/useGHBracePatients.ts

import { useState, useEffect } from 'react';
import GHBracePatientService from '../services/ghbracepatientService';
import { GHBracePatient } from '../models/GhBracePatient';
import showErrorToast from '../components/Toasts/ShowErrorToast';
import showSuccessToast from '../components/Toasts/ShowSuccessToast';
import showLoadingToast from '../components/Toasts/ShowLoadingToast';

const useGHBracePatients = () => {
    const [patients, setPatients] = useState<GHBracePatient[]>([]);
    const [totalPatients, setTotalPatients] = useState(Number(0));
    const [threePatients, setThreePatients] = useState<GHBracePatient[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedPatient, setSelectedPatient] = useState<GHBracePatient | null>(null);

    // Fetch all patients
    const fetchPatients = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await GHBracePatientService.getAllPatients();
            setPatients(data);
            setTotalPatients(data.length);
        } catch (error) {
            setError('Failed to fetch patients.');
            showErrorToast(`Failed fetching all brace patients ${error}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch all patients
    const fetchThreePatients = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await GHBracePatientService.getThreePatients();
            setThreePatients(data);
        } catch (error) {
            console.error('Failed to fetch patients:', error);
            setError('Failed to fetch patients.');
            showErrorToast(`Failed fetching three brace patients ${error}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch patient by ID
    const fetchPatientById = async (id: number) => {
        setLoading(true);
        setError(null);

        try {
            const patient = await GHBracePatientService.getPatientById(id);
            setSelectedPatient(patient);
            return patient;
        } catch (error) {
            console.error(`Failed to fetch patient with ID ${id}:`, error);
            setError(`Failed to fetch patient with ID ${id}.`);
            showErrorToast(`Failed fetching brace patient by id: ${id} ${error}`);
        } finally {
            setLoading(false);
        }
    };

    // Create new patient
    const createPatient = async (patientData: Omit<GHBracePatient, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);

        try {
            const newPatientRes = await GHBracePatientService.createPatient(patientData);
            setPatients(prev => [...prev, newPatientRes]);
            return newPatientRes;
        } catch (error) {
            console.error('Failed to create patient:', error);
            setError('Failed to create patient.');
            showErrorToast(`Failed creating brace patient ${error}`);
        } finally {
            setLoading(false);
        }
    };

    // Update existing patient
    const updatePatient = async (id: number, patientData: Omit<GHBracePatient, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);

        try {
            const updatedPatientRes = await GHBracePatientService.updatePatient(id, patientData);
            setPatients(prev =>
                prev.map(patient => (Number(patient.id) === id ? updatedPatientRes : patient))
            );
            return updatedPatientRes;
        } catch (error) {
            console.error(`Failed to update patient with ID ${id}:`, error);
            setError(`Failed to update patient with ID ${id}.`);
            showErrorToast(`Failed updating brace patient: ${id} ${error}`);
        } finally {
            setLoading(false);
        }
    };

    // Delete patient by ID
    const deletePatient = async (id: number) => {
        setLoading(true);
        setError(null);

        try {
            await GHBracePatientService.deletePatient(id);
            setPatients(prev => prev.filter(patient => Number(patient.id) !== id));
            showSuccessToast(`Patient ID ${id} deleted successfully.`);
        } catch (error) {
            console.error(`Failed to delete patient with ID ${id}:`, error);
            setError(`Failed to delete patient with ID ${id}.`);
            showErrorToast(`Failed deleting brace patient ${error}`);
        } finally {
            setLoading(false);
        }
    };

    // Hook to initialize fetching patients when component mounts
    useEffect(() => {
        fetchPatients();
        fetchThreePatients();
    }, []);

    return {
        patients,
        threePatients,
        totalPatients,
        loading,
        error,
        selectedPatient,
        fetchPatients,
        fetchThreePatients,
        fetchPatientById,
        createPatient,
        updatePatient,
        deletePatient,
    };
};

export default useGHBracePatients;
