import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../../components/Dropdown';
import { Link } from '../../../../components/Link';
import { Button } from '../../../../components/Button';
import { Heading, Subheading } from '../../../../components/Heading';
import { Input, InputGroup } from '../../../../components/Input';
import { Divider } from '../../../../components/Divider';
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import Pagination from '../../../../components/Pagination';
import GHBraceService from '../../../../services/ghbracepatientService'; // Updated import
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { GHBracePatient } from '../../../../models/GhBracePatient';
import useGHBracePatients from '../../../../hooks/useGhBracePatient';
import dateFormat from 'dateformat';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../components/Text';
import { Badge } from '../../../../components/Badge';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';

// Utility function to format date to yyyy-MM-dd based on local time
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);

    // Get the year, month, and day using local time
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
};

const GHBracePatientPage: React.FC = () => {
    const navigate = useNavigate();
    const { patients, loading, error, deletePatient, fetchPatients, totalPatients } = useGHBracePatients();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [patientToDelete, setPatientToDelete] = useState<number | null>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const { t } = useTranslation();

    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterID, setFilterID] = useState('');
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    // Filter patients based on the search query
    const filteredPatients = Array.isArray(patients)
        ? patients.filter((patient) => {
            const matchesSearch = patient.fullname?.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesStartDate = !filterStartDate || new Date(patient.created_time!) >= new Date(filterStartDate);
            const matchesEndDate = !filterEndDate || new Date(patient.created_time!) <= new Date(filterEndDate);
            const matchesYear = !filterYear || new Date(patient.created_time!).getFullYear().toString() === filterYear;
            const matchesID = !filterID || patient.reference_id?.toString().includes(filterID);

            return matchesSearch && matchesStartDate && matchesEndDate && matchesYear && matchesID;
        })
        : [];

    const handleOpenDeleteDialog = (id: number) => {
        setPatientToDelete(id);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (patientToDelete === null) return;

        setIsDeleting(true);
        try {
            await GHBraceService.deletePatient(patientToDelete);
            fetchPatients();
            setIsOpen(false);
        } catch (err) {
            console.error('Failed to delete patient');
        } finally {
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        document.title = `${t('brace_patients')}`
    }, []);

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>{t('brace_patients')}</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder={`${t('search')} ${t('patient')}${String.fromCharCode(8230)}`}
                                    value={searchQuery}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                {/* <Button outline onClick={() => navigate('/gh/braces/patients/images/add')}>Add new patient images</Button> */}
                <Button onClick={() => navigate('/gh/braces/patients/add')}>{t('create')} {t('new')} {t('patient')}</Button>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>{t('filters_and_sorting')}</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-6 sm:grid-cols-1'>
                    <div>
                        <Text>{t('by_id')}</Text>
                        <Input
                            type="text"
                            placeholder={`${t('patient_id')}`}
                            value={filterID}
                            onChange={(e) => setFilterID(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>{t('from_date')}</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>{t('to_date')}</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>{t('by_year')}</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterID('');
                        }}>
                            {t('reset')}{t('filter')}
                        </Button>
                    </div>
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {!loading && !error && filteredPatients.length === 0 && <p className='py-16 text-center'>No patient found.</p>}
                {!loading && !error && filteredPatients.map((patient, index) => (
                    <li key={patient.id.toString()}>
                        <Divider soft={index > 0} />
                        <div className="flex items-center justify-between">
                            <div className="flex gap-6 py-5">
                                <Avatar square initials={patient.fullname[0]} className="bg-zinc-300 text-gray-700 size-12" />
                                <div className="gap-1 grid grid-rows-2">
                                    <div className="text-base/6 font-bold">
                                        <Link href={`/gh/braces/patients/view/${patient.id.toString()}`}>{patient.fullname}</Link>
                                    </div>
                                    <div className="text-sm text-zinc-500">
                                        {patient.reference_id}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Badge className="max-sm:hidden" color='yellow'>
                                    {dateFormat(patient.created_time!, "mmmm dd, yyyy")}
                                </Badge>
                                <Dropdown>
                                    <DropdownButton plain aria-label="More options">
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                        <DropdownItem onClick={() => navigate(`/gh/braces/patients/view/${patient.id.toString()}`)}>{t('view')}</DropdownItem>
                                        <DropdownItem onClick={() => navigate(`/gh/braces/patients/edit/${patient.id.toString()}`)}>{t('edit')}</DropdownItem>
                                        {!(patient.brace_data_id) && <DropdownItem onClick={() => handleOpenDeleteDialog(Number(patient.id))}>{t('delete')}</DropdownItem>}
                                        {(patient.brace_data_id) ? <DropdownItem onClick={() => navigate(`/gh/braces/data/edit/${patient.brace_data_id!.toString()}`)}>{t('edit')} {t('brace_data')}</DropdownItem> : <DropdownItem onClick={() => navigate(`/gh/braces/data/add/${patient.id!.toString()}`)}>{t('create')} {t('brace_data')}</DropdownItem>}
                                        {(patient.brace_data_id) && <DropdownItem onClick={() => navigate(`/gh/braces/data/view/${patient.brace_data_id!.toString()}`)}>{t('view')} {t('brace_data')}</DropdownItem>}
                                        {(patient.brace_data_id) && <DropdownItem onClick={() => navigate(`/gh/braces/patients/images/add/${patient.id!.toString()}`)}>{t('add')} {t('patient_images')}</DropdownItem>}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Patient</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this patient? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GHBracePatientPage;
