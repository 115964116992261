import React, { ChangeEvent, useEffect, useState } from 'react';
import { useGHBraceData } from '../../../../../hooks/useGhBraceData';
import { GHBraceData } from '../../../../../models/GhBraceData';
import { Heading, Subheading } from '../../../../../components/Heading';
import { Button } from '../../../../../components/Button';
import { Divider } from '../../../../../components/Divider';
import { Radio, RadioField, RadioGroup } from '../../../../../components/Radio';
import { Input } from '../../../../../components/Input';
import { Label } from '../../../../../components/Fieldset';
import { Textarea } from '../../../../../components/Textarea';
import { useNavigate, useParams } from 'react-router-dom';
import useGHBracePatients from '../../../../../hooks/useGhBracePatient';
import Select from 'react-select';
import { GHBracePatient } from '../../../../../models/GhBracePatient';
import showSuccessToast from '../../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../../components/Toasts/ShowErrorToast';
import showLoadingToast from '../../../../../components/Toasts/ShowLoadingToast';
import { useTranslation } from 'react-i18next';
import { Code, Text } from '../../../../../components/Text';
import toast from 'react-hot-toast';

export interface AddGHBraceData {
    patient_id: bigint;
    date: string | null;
    brace_priority: string | null;
    armpit_size: string | null;
    armpit_ll: string | null;
    armpit_ap: string | null;
    xiphoid_size: string | null;
    xiphoid_ll: string | null;
    xiphoid_ap_left: string | null;
    xiphoid_ap_right: string | null;
    waist_size: string | null;
    waist_ll: string | null;
    waist_ap: string | null;
    spineheight_size: string | null;
    spineheight_ll: string | null;
    spineheight_ap: string | null;
    trocanter_size: string | null;
    trocanter_ll: string | null;
    trocanter_ap: string | null;
    spine_width: string | null;
    add2_parameter_1: string | null;
    add2_parameter_2: string | null;
    add2_parameter_3: string | null;
    add2_parameter_4: string | null;
    add2_parameter_5: string | null;
    additional_brace_info: string | null;
    scan_data: Blob | null;
    patient_images_xray: Blob | null;
    patient_images_back: Blob | null;
    patient_images_front: Blob | null;
    patient_images_left: Blob | null;
    patient_images_right: Blob | null;
    patient_images_forwardbending: Blob | null;
    visitxray_cobb_th: string | null;
    visitxray_cobb_i: string | null;
    visitxray_pic_xray: Blob | null;
    visitxray_pic_brace_back: Blob | null;
    visitxray_pic_brace_front: Blob | null;
    created_time: string | null;
    updated_time: string | null;
}

const AddBraceDataPageById: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { createData } = useGHBraceData();
    const navigate = useNavigate();
    const { patients, loading, error } = useGHBracePatients(); // Fetch patients
    const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
    const [selectedPatient, setSelectedPatient] = useState<GHBracePatient | null>(null);
    const { t } = useTranslation();

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        document.title = `${t('create')} ${t('new')} ${t('brace_data')}`
    }, []);

    const [braceData, setBraceData] = useState({
        patient_id: BigInt(id!),
        date: getTodayDate(),
        brace_priority: 'Primary Brace',
        armpit_size: '',
        armpit_ll: '',
        armpit_ap: '',
        xiphoid_size: '',
        xiphoid_ll: '',
        xiphoid_ap_left: '',
        xiphoid_ap_right: '',
        waist_size: '',
        waist_ll: '',
        waist_ap: '',
        spineheight_size: '',
        spineheight_ll: '',
        spineheight_ap: '',
        trocanter_size: '',
        trocanter_ll: '',
        trocanter_ap: '',
        spine_width: '',
        add2_parameter_1: '',
        add2_parameter_2: '',
        add2_parameter_3: '',
        add2_parameter_4: '',
        add2_parameter_5: '',
        additional_brace_info: '',
        visitxray_cobb_th: '',
        visitxray_cobb_i: '',
        // Add image fields and initialize them as null
        scan_data: null,
        patient_images_xray: null,
        patient_images_back: null,
        patient_images_front: null,
        patient_images_left: null,
        patient_images_right: null,
        patient_images_forwardbending: null,
        visitxray_pic_xray: null,
        visitxray_pic_brace_back: null,
        visitxray_pic_brace_front: null,
    });

    const [images, setImages] = useState({
        scan_data: null,
        patient_images_xray: null,
        patient_images_back: null,
        patient_images_front: null,
        patient_images_left: null,
        patient_images_right: null,
        patient_images_forwardbending: null,
        visitxray_pic_xray: null,
        visitxray_pic_brace_back: null,
        visitxray_pic_brace_front: null,
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBraceData({
            ...braceData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const file = e.target.files![0];
        if (file) {
            setFileNames((prevNames) => ({
                ...prevNames,
                [inputName]: file.name,
            }));
        }
        const { name, files } = e.target;
        if (files && files[0]) {
            setBraceData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
            setImages((prev) => ({ ...prev, [name]: file }));
        }
    };

    const handlePatientSelectChange = (selectedOption: any) => {
        const selectedPatient = patients.find(patient => patient.id === selectedOption.value);
        setSelectedPatient(selectedPatient || null);
        setBraceData((prev) => ({ ...prev, patient_id: selectedOption.value }));
    };

    const handleBracePriorityChange = (value: string) => {
        setBraceData((prev) => ({ ...prev, brace_priority: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!braceData) {
            return;
        }
        if (!braceData.date || !braceData.patient_id || !braceData.brace_priority) {
            showErrorToast(`Please fill in all required fields before submit`);
            return;
        };

        try {
            const toastId = showLoadingToast(`Creating ${selectedPatient!.fullname} ...`);
            const res = await createData(braceData, images);
            if (res.dbsuccess) {
                toast.dismiss(toastId);
                navigate('/gh/braces/patients');
                showSuccessToast(`${selectedPatient!.fullname}'s brace data added successfully`);
            } else {
                showErrorToast(`Error creating brace patient data`);
            }
        } catch (err) {
            showErrorToast(`Failed to add patient's brace data: ${err}`);
        }
    };

    const selectOptions = patients.map(patient => ({
        value: patient.id,
        label: patient.fullname // Adjust based on your patient model
    }));

    useEffect(() => {
        if (selectOptions.length > 0 && !braceData.patient_id) {
            setBraceData(prevData => ({
                ...prevData,
                patient_id: selectOptions[0].value  // Setting the first patient as default
            }));
        }
        const matchingPatient = patients.find((patient) => patient.id.toString() === id);
        if (matchingPatient) {
            setSelectedPatient(matchingPatient);
            setBraceData((prev) => ({ ...prev, patient_id: matchingPatient.id }));
        }
    }, [selectOptions]);

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>{t('create')}{t('brace_data')}</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" className="ml-3" disabled={(!braceData.date || !braceData.patient_id || !braceData.brace_priority)}>{t('save')}</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />

            {/* Patient ID Section */}
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-6 md:grid-cols-3">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_personal_information')}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t('patient_personal_information_description')}
                    </p>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className="sm:col-span-full">
                        <Subheading>{t('patient')}</Subheading>
                        <Select
                            id="patient_id"
                            name="patient_id"
                            options={selectOptions}
                            className="block w-full rounded-md border-0 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 dark:bg-[#182235]"
                            placeholder="Select Patient"
                            onChange={handlePatientSelectChange}
                            value={selectedPatient ? { value: selectedPatient.id, label: selectedPatient.fullname } : null}
                            isDisabled
                        />
                    </div>
                    <div className="sm:col-span-full">
                        <Subheading>{t('patient_id')}</Subheading>
                        <Text>{selectedPatient ? selectedPatient.reference_id : "-"}</Text>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>{t('brace')}</Subheading>
                            </div>
                            <RadioGroup
                                name="brace_priority"
                                value={braceData.brace_priority || ''}
                                onChange={handleBracePriorityChange}
                            >
                                <RadioField>
                                    <Radio value="Primary Brace"></Radio><Label>{t('primary_brace')}</Label>
                                </RadioField>
                                <RadioField>
                                    <Radio value="Secondary Brace"></Radio><Label>{t('secondary_brace')}</Label>
                                </RadioField>
                            </RadioGroup>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>{t('date')}</Subheading>
                            </div>
                            <Input
                                type="date"
                                aria-label="date"
                                name="date"
                                value={braceData.date || ''}
                                onChange={handleInputChange}
                            />
                        </section>
                    </div>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />

            {/* Patient Measurement Info */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_information')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('patient_information_description')}
                        </p>
                    </div>
                    <div className="sm:col-span-full">
                        <section className="grid grid-cols-2 gap-8 md:grid-cols-7">
                            <div className="grid gap-4 col-span-3">
                                <img
                                    src={'/images/BraceDataRefer1.png'}
                                    alt="Patient Measurement Info"
                                    className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                />
                            </div>

                            <div className="py-2">
                                <div className="grid grid-rows-7 grid-flow-col gap-1.5">
                                    <div></div>
                                    {[t('armpit'), t('xiphoid'), t('waist'), t('spine_height'), t('trocanter'), t('spine_width')].map((label, index) => (
                                        <div key={index} className="text-md py-1.5 my-1">{label}</div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-7 grid-flow-col text-center gap-4">
                                    <div>{t('size')}</div>
                                    {['armpit_size', 'xiphoid_size', 'waist_size', 'spineheight_size', 'trocanter_size', 'spine_width'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`${name.replace(/size$/, ' Size').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-7 grid-flow-col text-center gap-4">
                                    <div>{t('ll')}</div>
                                    {['armpit_ll', 'xiphoid_ll', 'waist_ll', 'spineheight_ll', 'trocanter_ll'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`${name.replace(/ll$/, ' L-L').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-7 grid-flow-col text-center gap-4">
                                    <div>{t('ap')}</div>
                                    <Input
                                        type="text"
                                        aria-label={'armpit_ap'}
                                        name={'armpit_ap'}
                                        placeholder={'Armpit A-P'}
                                        onChange={handleInputChange}
                                    />
                                    <div className="grid grid-cols-2 grid-flow-col text-center gap-4">
                                        <div>
                                            <Input
                                                type="text"
                                                aria-label={'xiphoid_ap_left'}
                                                name={'xiphoid_ap_left'}
                                                placeholder={'A-P Left'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                aria-label={'xiphoid_ap_right'}
                                                name={'xiphoid_ap_right'}
                                                placeholder={'A-P Right'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    {['waist_ap', 'spineheight_ap', 'trocanter_ap'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`${name.replace(/ap$/, ' A-P').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section className="grid grid-cols-2 gap-8 md:grid-cols-7">
                            <div className="grid gap-4 col-span-3">
                                <img
                                    src={'/images/BraceDataRefer2.png'}
                                    alt="Patient Measurement Info"
                                    className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                />
                            </div>
                            <div className="py-2">
                                <div className="grid grid-rows-6 grid-flow-col gap-1.5">
                                    <div></div>
                                    {[t('parameter') + ' 1', t('parameter') + ' 2', t('parameter') + ' 3', t('parameter') + ' 4', t('parameter') + ' 5'].map((label, index) => (
                                        <div key={index} className="text-md py-1.5 my-1">{label}</div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="grid grid-rows-6 grid-flow-col text-center gap-4">
                                    <div></div>
                                    {['add2_parameter_1', 'add2_parameter_2', 'add2_parameter_3', 'add2_parameter_4', 'add2_parameter_5'].map((name, index) => (
                                        <div key={index}>
                                            <Input
                                                type="text"
                                                aria-label={name}
                                                name={name}
                                                placeholder={`Parameter ${index + 1}`}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <Divider className="mt-5 mb-8" />

            {/* Patient Images Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_images')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('patient_images_description')}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        {['patient_images_xray', 'patient_images_back', 'patient_images_front', 'patient_images_left', 'patient_images_right', 'patient_images_forwardbending'].map((name, index) => (
                            <div key={index} className="sm:col-span-full">
                                <section>
                                    <Subheading>{`${name.replace(/patient_images_/g, '').replace(/\b\w/g, char => char.toUpperCase())}`}</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                        <div className="text-center">
                                            <div className="mt-4 text-sm leading-6 text-gray-600">
                                                <div className="mt-4 text-sm leading-6 text-gray-600">
                                                    <label
                                                        htmlFor={`file-upload-${name}`}
                                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500 "
                                                    >
                                                        <span>{t("upload_an_image")}</span>
                                                        <input
                                                            id={`file-upload-${name}`}
                                                            name={name}
                                                            type="file"
                                                            className="sr-only"
                                                            accept="image/*"
                                                            onChange={(e) => handleFileChange(e, `${name}`)}
                                                        />
                                                    </label>

                                                </div>
                                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG up to 8MB</p>
                                            </div>
                                        </div>
                                    </div>
                                    {fileNames[`${name}`] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames[`${name}`]}</strong>
                                        </p>
                                    )}
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Divider className="mt-5 mb-8" />

            {/* Additional Brace Info Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('additional_brace_info')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('additional_brace_info_description')}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t("additional_brace_info")}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="additional_brace_info"
                                        name="additional_brace_info"
                                        value={braceData.additional_brace_info || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <Divider className="mt-5 mb-8" />

            {/* Scan Data Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-8 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t('patient_scan_data')}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t('patient_scan_data_description')}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className=''>
                                <div className="col-span-full">
                                    <Subheading>{t("upload")}{t("patient_scan_data")}</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                        <div className="text-center">
                                            <div className="mt-4 text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload-scan_data"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500"
                                                >
                                                    <span>{t("upload_a_file")}</span>
                                                    <input
                                                        id="file-upload-scan_data"
                                                        name="scan_data"
                                                        type="file"
                                                        accept=".stl, .zip, .rar, .jpg"
                                                        onChange={(e) => handleFileChange(e, 'scan_data')}
                                                        className="sr-only"
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">STL, ZIP, RAR up to 10MB</p>
                                        </div>

                                    </div>
                                    {fileNames['scan_data'] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames.scan_data}</strong>
                                        </p>
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            {/* Visit with XRay Section */}
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("visit_with_xray")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("visit_with_xray_description")}
                        </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t("cobb_angle_thoracic")}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="visitxray_cobb_th"
                                        name="visitxray_cobb_th"
                                        value={braceData.visitxray_cobb_th || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>{t("cobb_angle_lumbar")}</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="visitxray_cobb_i"
                                        name="visitxray_cobb_i"
                                        value={braceData.visitxray_cobb_i || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </section>
                        </div>
                        {['visitxray_pic_xray', 'visitxray_pic_brace_back', 'visitxray_pic_brace_front'].map((name, index) => (
                            <div key={index} className="sm:col-span-full">
                                <section>
                                    <Subheading>{`${name.replace(/visitxray_pic_/g, 'Picture with Brace: ').replace(/\b\w/g, char => char.toUpperCase())}`}</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                        <div className="text-center">
                                            <div className="mt-4 text-sm text-gray-600">
                                                <label
                                                    htmlFor={`file-upload-${name}`}
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2 hover:text-red-500"
                                                >
                                                    <span>{t("upload_an_image")}</span>
                                                    <input
                                                        id={`file-upload-${name}`}
                                                        name={name}
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => handleFileChange(e, `${name}`)}
                                                        className="sr-only"
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG up to 8MB</p>
                                        </div>
                                    </div>
                                    {fileNames[`${name}`] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames[`${name}`]}</strong>
                                        </p>
                                    )}
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        {t("cancel")}
                    </Button>
                    <Button type="submit" disabled={(!braceData.date || !braceData.patient_id || !braceData.brace_priority)}>{t("save")}</Button>
                </div>
            </div>
        </form>

    );
};

export default AddBraceDataPageById;
