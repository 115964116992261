import axios from 'axios';
import { GHBraceData } from '../models/GhBraceData';
import { GetImagesResponse } from '../models/ImagesModel';

const API_URL = process.env.REACT_APP_SERVER_URL + '/ghbracedata';

export const GHBraceDataService = {

    getAll: async (): Promise<GHBraceData[]> => {
        const response = await axios.get(`${API_URL}/getAllBraceData`);
        return response.data;
    },


    getById: async (id: number): Promise<GHBraceData> => {
        const response = await axios.get(`${API_URL}/getBraceDataById/${id}`);
        return response.data;
    },

    getIdByPatientId: async (id: number): Promise<GHBraceData> => {
        try {
            const response = await axios.get(`${API_URL}/getBraceDataIdByPatientId/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error retrieving brace data by patient id:', error);
            throw error;
        }
    },

    getByPatientId: async (id: number): Promise<GHBraceData> => {

        try {
            const response = await axios.get(`${API_URL}/getBraceDataByPatientId/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error retrieving brace data by patient id:', error);
            throw error;
        }
    },

    getImagesById: async (id: number): Promise<GetImagesResponse> => {
        try {
            // Make GET request to retrieve patient images
            const response = await axios.get(`${API_URL}/getBraceDataImagesById/${id}`);
            return response.data; // Should match the GetImagesResponse type
        } catch (error) {
            console.error('Error retrieving brace data images:', error);
            throw error;
        }
    },

    create: async (braceData: Omit<GHBraceData, 'id' | 'created_time' | 'updated_time'>, images: any) => {
        try {
            const formData = new FormData();

            // Append non-image fields
            formData.append('patient_id', braceData.patient_id!.toString());
            formData.append('date', braceData.date || '');
            formData.append('brace_priority', braceData.brace_priority || '');
            formData.append('armpit_size', braceData.armpit_size || '');
            formData.append('armpit_ll', braceData.armpit_ll || '');
            formData.append('armpit_ap', braceData.armpit_ap || '');
            formData.append('xiphoid_size', braceData.xiphoid_size || '');
            formData.append('xiphoid_ll', braceData.xiphoid_ll || '');
            formData.append('xiphoid_ap_left', braceData.xiphoid_ap_left || '');
            formData.append('xiphoid_ap_right', braceData.xiphoid_ap_right || '');
            formData.append('waist_size', braceData.waist_size || '');
            formData.append('waist_ll', braceData.waist_ll || '');
            formData.append('waist_ap', braceData.waist_ap || '');
            formData.append('spineheight_size', braceData.spineheight_size || '');
            formData.append('spineheight_ll', braceData.spineheight_ll || '');
            formData.append('spineheight_ap', braceData.spineheight_ap || '');
            formData.append('trocanter_size', braceData.trocanter_size || '');
            formData.append('trocanter_ll', braceData.trocanter_ll || '');
            formData.append('trocanter_ap', braceData.trocanter_ap || '');
            formData.append('spine_width', braceData.spine_width || '');
            formData.append('add2_parameter_1', braceData.add2_parameter_1 || '');
            formData.append('add2_parameter_2', braceData.add2_parameter_2 || '');
            formData.append('add2_parameter_3', braceData.add2_parameter_3 || '');
            formData.append('add2_parameter_4', braceData.add2_parameter_4 || '');
            formData.append('add2_parameter_5', braceData.add2_parameter_5 || '');
            formData.append('additional_brace_info', braceData.additional_brace_info || '');
            formData.append('visitxray_cobb_th', braceData.visitxray_cobb_th || '');
            formData.append('visitxray_cobb_i', braceData.visitxray_cobb_i || '');

            // Append image files (assuming they are provided as `File` or `Blob` objects)
            if (images.scan_data) formData.append('scan_data', images.scan_data);
            if (images.patient_images_xray) formData.append('patient_images_xray', images.patient_images_xray);
            if (images.patient_images_back) formData.append('patient_images_back', images.patient_images_back);
            if (images.patient_images_front) formData.append('patient_images_front', images.patient_images_front);
            if (images.patient_images_left) formData.append('patient_images_left', images.patient_images_left);
            if (images.patient_images_right) formData.append('patient_images_right', images.patient_images_right);
            if (images.patient_images_forwardbending) formData.append('patient_images_forwardbending', images.patient_images_forwardbending);
            if (images.visitxray_pic_xray) formData.append('visitxray_pic_xray', images.visitxray_pic_xray);
            if (images.visitxray_pic_brace_back) formData.append('visitxray_pic_brace_back', images.visitxray_pic_brace_back);
            if (images.visitxray_pic_brace_front) formData.append('visitxray_pic_brace_front', images.visitxray_pic_brace_front);

            // Send the form data using axios
            const response = await axios.post(`${API_URL}/add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error creating brace data:', error);
            throw error;
        }
    },

    update: async (id: number, braceData: Omit<GHBraceData, 'id' | 'created_time' | 'updated_time'>, updatedImages: any) => {
        try {
            const updateFormData = new FormData();

            // Append non-image fields
            updateFormData.append('patient_id', braceData.patient_id!.toString());
            updateFormData.append('date', braceData.date || '');
            updateFormData.append('brace_priority', braceData.brace_priority || '');
            updateFormData.append('armpit_size', braceData.armpit_size || '');
            updateFormData.append('armpit_ll', braceData.armpit_ll || '');
            updateFormData.append('armpit_ap', braceData.armpit_ap || '');
            updateFormData.append('xiphoid_size', braceData.xiphoid_size || '');
            updateFormData.append('xiphoid_ll', braceData.xiphoid_ll || '');
            updateFormData.append('xiphoid_ap_left', braceData.xiphoid_ap_left || '');
            updateFormData.append('xiphoid_ap_right', braceData.xiphoid_ap_right || '');
            updateFormData.append('waist_size', braceData.waist_size || '');
            updateFormData.append('waist_ll', braceData.waist_ll || '');
            updateFormData.append('waist_ap', braceData.waist_ap || '');
            updateFormData.append('spineheight_size', braceData.spineheight_size || '');
            updateFormData.append('spineheight_ll', braceData.spineheight_ll || '');
            updateFormData.append('spineheight_ap', braceData.spineheight_ap || '');
            updateFormData.append('trocanter_size', braceData.trocanter_size || '');
            updateFormData.append('trocanter_ll', braceData.trocanter_ll || '');
            updateFormData.append('trocanter_ap', braceData.trocanter_ap || '');
            updateFormData.append('spine_width', braceData.spine_width || '');
            updateFormData.append('add2_parameter_1', braceData.add2_parameter_1 || '');
            updateFormData.append('add2_parameter_2', braceData.add2_parameter_2 || '');
            updateFormData.append('add2_parameter_3', braceData.add2_parameter_3 || '');
            updateFormData.append('add2_parameter_4', braceData.add2_parameter_4 || '');
            updateFormData.append('add2_parameter_5', braceData.add2_parameter_5 || '');
            updateFormData.append('additional_brace_info', braceData.additional_brace_info || '');
            updateFormData.append('visitxray_cobb_th', braceData.visitxray_cobb_th || '');
            updateFormData.append('visitxray_cobb_i', braceData.visitxray_cobb_i || '');

            // Append image files (assuming they are provided as `File` or `Blob` objects)
            if (updatedImages.scan_data) updateFormData.append('scan_data', updatedImages.scan_data);
            if (updatedImages.patient_images_xray) updateFormData.append('patient_images_xray', updatedImages.patient_images_xray);
            if (updatedImages.patient_images_back) updateFormData.append('patient_images_back', updatedImages.patient_images_back);
            if (updatedImages.patient_images_front) updateFormData.append('patient_images_front', updatedImages.patient_images_front);
            if (updatedImages.patient_images_left) updateFormData.append('patient_images_left', updatedImages.patient_images_left);
            if (updatedImages.patient_images_right) updateFormData.append('patient_images_right', updatedImages.patient_images_right);
            if (updatedImages.patient_images_forwardbending) updateFormData.append('patient_images_forwardbending', updatedImages.patient_images_forwardbending);
            if (updatedImages.visitxray_pic_xray) updateFormData.append('visitxray_pic_xray', updatedImages.visitxray_pic_xray);
            if (updatedImages.visitxray_pic_brace_back) updateFormData.append('visitxray_pic_brace_back', updatedImages.visitxray_pic_brace_back);
            if (updatedImages.visitxray_pic_brace_front) updateFormData.append('visitxray_pic_brace_front', updatedImages.visitxray_pic_brace_front);

            // // Log formData content using forEach
            // updateFormData.forEach((value, key) => {
            //     console.log(`${key}:`, value);
            // });

            // Send the form data using axios
            const response = await axios.put(`${API_URL}/update/${id}`, updateFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error updating brace data:', error);
            throw error;
        }
    },

    delete: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};

export default GHBraceDataService;

